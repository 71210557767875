import { render, staticRenderFns } from "./DocDetail.vue?vue&type=template&id=2d727508&scoped=true&"
import script from "./DocDetail.vue?vue&type=script&lang=js&"
export * from "./DocDetail.vue?vue&type=script&lang=js&"
import style0 from "./DocDetail.vue?vue&type=style&index=0&id=2d727508&prod&lang=scss&scoped=true&"
import style1 from "./DocDetail.vue?vue&type=style&index=1&id=2d727508&prod&lang=scss&"
import style2 from "./DocDetail.vue?vue&type=style&index=2&id=2d727508&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d727508",
  null
  
)

export default component.exports