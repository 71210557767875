import qs from 'qs'
// import { SERVER_TYPE } from '@Compute/constants'
// import VncInfoFetcher from '@Compute/sections/VncInfoFetcher'
import { disableDeleteAction } from '@/utils/common/tableActions'
import {
  typeClouds,
  // findPlatform
} from '@/utils/common/hypervisor'
import i18n from '@/locales'
import { HOST_CPU_ARCHS } from '@/constants/compute'
import { HYPERVISORS_MAP, PROVIDER_MAP } from '@/constants'
import { hasSetupKey } from '@/utils/auth'
// import { KVM_SHARE_STORAGES } from '@/constants/storage'
import { POLICY_RES_NAME_KEY_MAP } from '@/constants/policy'
import { commonUnabled, cloudEnabled, cloudUnabledTip, commonEnabled, commonTip } from '../utils'

const getSingleActions = function () {
  return [
    {
      label: i18n.t('compute.text_341'),
      permission: 'server_get_vnc',
      actions: obj => {
        let ret = []
        // const vncRemote = {
        //   label: i18n.t('compute.text_1274'),
        //   action: () => {
        //     const success = () => {
        //       const isValidURL = str => {
        //         const regex = /(\w+):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!-/]))?/
        //         if (!regex.test(str)) {
        //           return false
        //         } else {
        //           return true
        //         }
        //       }
        //       const params = {}
        //       if (obj.hypervisor === typeClouds.hypervisorMap.openstack.key) {
        //         params.origin = true
        //       }
        //       this.webconsoleManager.performAction({
        //         id: 'server',
        //         action: obj.id,
        //         data: params,
        //       }).then(({ data }) => {
        //         if (isValidURL(data.connect_params)) {
        //           this.open(obj, data.connect_params)
        //         } else {
        //           this.openWebConsole(obj, data)
        //         }
        //       })
        //     }
        //     if (this.enableMFA) {
        //       this.createDialog('SecretVertifyDialog', {
        //         success,
        //       })
        //     } else {
        //       success()
        //     }
        //   },
        //   meta: () => {
        //     const ret = {
        //       validate: obj.power_states === 'unknown' ? cloudEnabled('vnc', obj) : obj.power_states === 'on',
        //       tooltip: obj.power_states === 'unknown' ? cloudUnabledTip('vnc', obj) : '',
        //     }
        //     return ret
        //   },
        // }
        // if (obj.provider === 'OneCloud' && obj.status === 'running') {
        //   vncRemote.render = (obj, params, h) => {
        //     return <VncInfoFetcher onManager={this.onManager} row={obj} buttonText={i18n.t('compute.text_1274')} buttonProps={params} />
        //   }
        // }
        // ret.push(vncRemote)
        const mapIpActions = (ipInfoList) => {
          const options = []
          ipInfoList.forEach(ipInfo => {
            const actionType = ipInfo.actionType
            const ipAddr = ipInfo.ipAddr
            const meta = () => {
              const ret = {
                validate: false,
                tooltip: null,
              }
              if (obj.os_type === 'Windows') {
                ret.tooltip = i18n.t('compute.text_344')
                return ret
              }
              ret.validate = cloudEnabled(actionType, obj)
              ret.tooltip = cloudUnabledTip(actionType, obj)
              return ret
            }

            const fetchWebconsoleAddr = async (port) => {
              if (ipInfo.vpcId === 'default' || ipInfo.ipType === 'eip' || ipInfo.provider === 'InCloudSphere') {
                return {
                  ipAddr: ipAddr,
                  port: port,
                }
              }
              if (ipInfo.provider === 'OneCloud') {
                return new this.$Manager('servers').performAction({
                  id: obj.id,
                  action: 'list-forward',
                  data: {
                    proto: 'tcp',
                    port: port,
                  },
                }).then(data => {
                  const fwds = data.data.forwards
                  if (fwds && fwds.length > 0) {
                    const fwd = fwds[0]
                    return {
                      ipAddr: fwd.proxy_addr,
                      port: fwd.proxy_port,
                    }
                  }
                  return new this.$Manager('servers').performAction({
                    id: obj.id,
                    action: 'open-forward',
                    data: {
                      proto: 'tcp',
                      port: port,
                    },
                  }).then(data => {
                    const fwd = data.data
                    return {
                      ipAddr: fwd.proxy_addr,
                      port: fwd.proxy_port,
                    }
                  })
                })
              }
              return Promise.reject(Error(`unexpected ${ipInfo}`))
            }

            const openWebconsole = async (port, id) => {
              const addr = await fetchWebconsoleAddr(port)
              const params = {
                id: 'ssh',
                action: addr.ipAddr,
                data: {
                  id,
                  port: addr.port,
                  type: 'server',
                },
              }
              this.webconsoleManager.performAction(params).then(({ data }) => {
                const connectParams = qs.parse(data.connect_params)
                // 验证账号密码
                if (connectParams.is_need_login === 'true') {
                  this.createDialog('SshAuthDialog', {
                    manager: this.webconsoleManager,
                    params,
                    errorMsg: connectParams.login_error_message,
                    data: { name: obj.name, ip: addr.ipAddr },
                    success: (data) => {
                      this.openWebConsole(obj, data, 'ws')
                    },
                  })
                  return
                }
                // 无需验证账号密码
                this.openWebConsole(obj, data, 'ws')
              })
            }

            options.push({
              label: `SSH ${ipAddr}`,
              permission: 'server_perform_list_forward,server_perform_open_forward',
              action: () => {
                const success = () => {
                  openWebconsole(22, obj.id)
                }
                if (this.enableMFA) {
                  this.createDialog('SecretVertifyDialog', {
                    success,
                  })
                } else {
                  success()
                }
              },
              meta,
            })
            options.push({
              label: i18n.t('compute.text_345', [ipAddr]),
              permission: 'server_perform_list_forward,server_perform_open_forward',
              action: () => {
                const success = () => {
                  this.createDialog('SmartFormDialog', {
                    title: i18n.t('compute.text_346'),
                    data: [obj],
                    callback: async (data) => {
                      openWebconsole(data.port, obj.id)
                    },
                    decorators: {
                      port: [
                        'port',
                        {
                          validateFirst: true,
                          rules: [
                            { required: true, message: i18n.t('compute.text_347') },
                            {
                              validator: (rule, value, _callback) => {
                                const num = parseFloat(value)
                                if (!/^\d+$/.test(value) || !num || num > 65535) {
                                  _callback(i18n.t('compute.text_348'))
                                }
                                _callback()
                              },
                            },
                          ],
                        },
                        {
                          label: i18n.t('compute.text_349'),
                          placeholder: i18n.t('compute.text_350'),
                        },
                      ],
                    },
                  })
                }
                if (this.enableMFA) {
                  this.createDialog('SecretVertifyDialog', {
                    success,
                  })
                } else {
                  success()
                }
              },
              meta,
            })
          })
          return options
        }

        const ipInfoList = []
        if (obj.eip) {
          obj.eip.split(',').filter(item => !!item).map(ip => {
            ipInfoList.push({
              actionType: 'EIP SSH',
              ipType: 'eip',
              ipAddr: ip,
            })
          })
        }
        if (obj.nics) {
          obj.nics.map(nic => {
            if ((obj.provider === 'OneCloud' || obj.vpc_id === 'default' || obj.provider === 'InCloudSphere') && nic.ip_addr) {
              ipInfoList.push({
                actionType: 'IP SSH',
                ipType: 'nicIP',
                ipAddr: nic.ip_addr,
                vpcId: nic.vpc_id,
                provider: obj.provider,
              })
            }
          })
        }
        const sshActions = mapIpActions(ipInfoList)
        ret = ret.concat(sshActions)
        return ret
      },
      meta: (obj) => {
        let ret = {
          validate: true,
          tooltip: null,
        }
        ret = this.$isValidateResourceLock(obj)
        return ret
      },
      hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_web_console'),
    },
    {
      label: i18n.t('compute.text_352'),
      actions: (obj) => {
        return [
          // * 实例状态
          {
            label: i18n.t('compute.text_353'),
            submenus: [
              // 同步状态
              {
                label: i18n.t('compute.perform_sync_status'),
                permission: 'server_perform_syncstatus',
                action: () => {
                  this.onManager('performAction', {
                    steadyStatus: ['running', 'ready'],
                    id: obj.id,
                    managerArgs: {
                      action: 'syncstatus',
                    },
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (commonUnabled(obj)) return ret
                  ret.validate = true
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_syncstatus'),
              },
              // 开机
              {
                label: i18n.t('compute.text_272'),
                permission: 'server_perform_start',
                action: () => {
                  this.onManager('performAction', {
                    steadyStatus: 'running',
                    id: obj.id,
                    managerArgs: {
                      action: 'start',
                    },
                  })
                },
                meta: () => {
                  return {
                    validate: cloudEnabled('start', obj),
                  }
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_start'),
              },
              // 关机
              {
                label: i18n.t('compute.text_273'),
                permission: 'server_perform_stop',
                action: () => {
                  this.createDialog('VmShutDownDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  return { validate: cloudEnabled('stop', obj) }
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_stop'),
              },
              // 重启
              {
                label: i18n.t('compute.text_274'),
                permission: 'server_perform_restart',
                action: () => {
                  this.createDialog('VmRestartDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  return {
                    validate: cloudEnabled('restart', obj),
                  }
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_restart'),
              },
              // 重置
              // {
              //   label: i18n.t('compute.text_354'),
              //   permission: 'server_perform_reset',
              //   action: () => {
              //     this.createDialog('VmResetDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.hypervisor === typeClouds.hypervisorMap.bingocloud.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [typeClouds.hypervisorMap.bingocloud.label])
              //       return ret
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     return {
              //       validate: (obj.status === 'running' || obj.status === 'stop_fail') && !commonUnabled(obj),
              //     }
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_reset'),
              // },
              // 挂起
              // {
              //   label: i18n.t('compute.text_1128'),
              //   permission: 'server_perform_suspend',
              //   action: () => {
              //     this.createDialog('VmSuspendDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     // const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     // if (![typeClouds.hypervisorMap.esxi.key, typeClouds.hypervisorMap.kvm.key].includes(obj.hypervisor)) {
              //     //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //     //   return ret
              //     // }
              //     if (obj.status !== 'running') {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_1130')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['vmware'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_suspend'),
              // },
              // 恢复
              // {
              //   label: i18n.t('compute.text_478'),
              //   permission: 'server_perform_resume',
              //   action: () => {
              //     this.createDialog('VmResumeDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     // const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     // if (obj.hypervisor !== typeClouds.hypervisorMap.esxi.key && obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //     //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //     //   return ret
              //     // }
              //     if (obj.status !== 'suspend') {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_1131')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['vmware'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_resume'),
              // },
              // 推送配置
              // {
              //   label: i18n.t('compute.sync_config'),
              //   permission: 'server_perform_sync_config',
              //   action: () => {
              //     this.createDialog('VmSyncConfigDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     // const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     // if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //     //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //     //   return ret
              //     // }
              //     if (obj.status !== 'running' && obj.status !== 'ready') {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_1126')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_sync_config'),
              // },
            ],
          },
          // * 属性设置
          {
            label: i18n.t('compute.text_356'),
            submenus: [
              // 修改属性
              {
                label: i18n.t('compute.text_247'),
                permission: 'server_update',
                action: () => {
                  this.createDialog('VmUpdateDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: (row) => {
                  const isOneCloud = row.brand === 'OneCloud'
                  const provider = obj.provider
                  return {
                    validate: isOneCloud,
                    tooltip: !isOneCloud && i18n.t('compute.text_473', [PROVIDER_MAP[provider].label]),
                  }
                },
                hidden: (row) => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_update') ||
                obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              },
              // 创建相同配置
              // {
              //   label: i18n.t('compute.text_359'),
              //   permission: 'server_create',
              //   action: () => {
              //     this.$openNewWindowForMenuHook('vminstance_configured_callback_address.create_same_configuration_callback_address', () => {
              //       this.createDialog('VmCloneDialog', {
              //         data: [obj],
              //         columns: this.columns,
              //         onManager: this.onManager,
              //       })
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.is_prepaid_recycle) {
              //       ret.tooltip = i18n.t('compute.text_285')
              //       return ret
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key &&
              //       obj.hypervisor !== typeClouds.hypervisorMap.esxi.key &&
              //       findPlatform(obj.hypervisor) !== SERVER_TYPE.public) {
              //       ret.tooltip = i18n.t('compute.text_473', [typeClouds.hypervisorMap[obj.hypervisor]?.label || ''])
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud', 'public'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_create_same_config'),
              // },
              // 更改项目
              {
                label: i18n.t('compute.perform_change_owner', [i18n.t('dictionary.project')]),
                permission: 'server_perform_change_owner',
                action: () => {
                  this.createDialog('ChangeOwenrDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    name: i18n.t('dictionary.server'),
                    resource: 'servers',
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (!this.isAdminMode && !this.isDomainMode) {
                    ret.tooltip = i18n.t('compute.text_613')
                    return ret
                  }
                  if (commonUnabled(obj)) return ret
                  ret.validate = true
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_change_owner'),
              },
              // 到期释放
              {
                label: i18n.t('compute.text_1132'),
                permission: 'server_perform_cancel_expire',
                action: () => {
                  this.createDialog('SetDurationDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    alert: i18n.t('compute.text_1391'),
                    refresh: this.refresh,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (obj.billing_type === 'prepaid') {
                    ret.tooltip = i18n.t('compute.text_285')
                    return ret
                  }
                  if (obj.hypervisor === typeClouds.hypervisorMap.bingocloud.key) {
                    ret.tooltip = i18n.t('compute.text_473', [typeClouds.hypervisorMap.bingocloud.label])
                    return ret
                  }
                  ret.validate = true
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_cancel_expire'),
              },
              // 加入反亲和组
              // {
              //   label: i18n.t('compute.text_1181', [i18n.t('dictionary.instancegroup')]),
              //   permission: 'server_perform_bind_groups,server_perform_unbind_groups',
              //   action: () => {
              //     this.createDialog('VmBindInstanceGroupDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       refresh: this.refresh,
              //       name: i18n.t('dictionary.server'),
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     if (!['running', 'ready'].includes(obj.status)) {
              //       ret.tooltip = i18n.t('compute.text_1126')
              //       return ret
              //     }
              //     if (obj.backup_host_id) {
              //       ret.tooltip = i18n.t('compute.text_1283')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_add_instancegroup') ||
              //   obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
              // 主机克隆
              // {
              //   label: i18n.t('compute.text_1208'),
              //   permission: 'server_perform_snapshot_and_clone',
              //   action: () => {
              //     this.$openNewWindowForMenuHook('vminstance_configured_callback_address.host_clone_callback_address', () => {
              //       this.createDialog('VmCloneDeepDialog', {
              //         data: [obj],
              //         columns: this.columns,
              //         onManager: this.onManager,
              //         refresh: this.refresh,
              //       })
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     if (!['running', 'ready'].includes(obj.status)) {
              //       ret.tooltip = i18n.t('compute.text_1126')
              //       return ret
              //     }
              //     if (obj.backup_host_id) {
              //       ret.tooltip = i18n.t('compute.text_1283')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_clone') ||
              //   obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
              // 添加到堡垒机
              // {
              //   label: i18n.t('compute.bastionHost.add_bastion_host'),
              //   action: () => {
              //     this.createDialog('VmAddBastionHostDialog', {
              //       data: [obj],
              //     })
              //   },
              // },
              // 续费
              // {
              //   label: i18n.t('compute.text_1117'),
              //   permission: 'server_perform_renew',
              //   action: () => {
              //     this.$openNewWindowForMenuHook('vminstance_configured_callback_address.renew_callback_address', () => {
              //       this.createDialog('VmResourceFeeDialog', {
              //         data: [obj],
              //         columns: this.columns,
              //         onManager: this.onManager,
              //       })
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (findPlatform(obj.hypervisor) !== SERVER_TYPE.public) {
              //       ret.tooltip = i18n.t('compute.text_1118')
              //       return ret
              //     }
              //     if (obj.billing_type !== 'prepaid') {
              //       ret.tooltip = i18n.t('compute.text_1119')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['aliyun', 'qcloud', 'huawei', 'ucloud', 'ecloud', 'jdcloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_Renew'),
              // },
              // 自动续费设置
              // {
              //   label: i18n.t('compute.text_1120'),
              //   permission: 'server_perform_aet_auto_renew',
              //   action: () => {
              //     this.createDialog('VmResourceRenewFeeDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       refresh: this.refresh,
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (findPlatform(obj.hypervisor) !== SERVER_TYPE.public) {
              //       ret.tooltip = i18n.t('compute.text_1118')
              //       return ret
              //     }
              //     if (obj.billing_type !== 'prepaid') {
              //       ret.tooltip = i18n.t('compute.text_1119')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['aliyun', 'qcloud', 'huawei', 'ucloud', 'ecloud', 'jdcloud', 'ctyun'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_auto_renewal'),
              // },
            ],
          },
          // * 配置修改
          {
            label: i18n.t('compute.group_action.update_config'),
            submenus: [
              // 重装系统
              {
                label: i18n.t('compute.text_357'),
                permission: 'server_perform_rebuild_root',
                action: () => {
                  this.createDialog('VmRebuildRootDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (obj.shutdown_mode === 'stop_charging') {
                    ret.validate = false
                    ret.tooltip = i18n.t('compute.server.shutdown_mode.tooltip')
                    return ret
                  }
                  if (commonUnabled(obj)) return ret
                  ret.validate = cloudEnabled('rebuildRoot', obj)
                  ret.tooltip = cloudUnabledTip('rebuildRoot', obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_rebuild_root'),
              },
              // 调整配置
              {
                label: i18n.t('compute.text_1100'),
                permission: 'server_perform_change_config',
                action: () => {
                  this.$openNewWindowForMenuHook('vminstance_configured_callback_address.adjust_configuration_callback_address', () => {
                    this.$router.push({
                      name: 'VMInstanceAdjustConfig',
                      query: {
                        id: obj.id,
                      },
                    })
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (obj.backup_host_id) {
                    ret.tooltip = i18n.t('compute.text_1111')
                    return ret
                  }
                  if (obj.os_arch === HOST_CPU_ARCHS.arm.key && obj.status === 'running') {
                    ret.tooltip = i18n.t('compute.text_1371')
                    return ret
                  }
                  if (obj.shutdown_mode === 'stop_charging') {
                    ret.validate = false
                    ret.tooltip = i18n.t('compute.server.shutdown_mode.tooltip')
                    return ret
                  }
                  if (commonUnabled(obj)) return ret
                  ret.validate = cloudEnabled('adjustConfig', obj)
                  ret.tooltip = cloudUnabledTip('adjustConfig', obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_change_config'),
              },
              // 设置GPU卡
              // {
              //   label: i18n.t('compute.text_1112'),
              //   permission: 'server_perform_set_isolated_device,attach-isolated-device,server_perform_detach_isolated_device',
              //   action: () => {
              //     this.createDialog('VmAttachGpuDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (!this.isAdminMode && !this.isDomainMode) {
              //       ret.tooltip = i18n.t('compute.text_1279', [i18n.t('dictionary.domain')])
              //       return ret
              //     }
              //     if (findPlatform(obj.hypervisor, 'hypervisor') !== SERVER_TYPE.idc) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     ret.validate = cloudEnabled('acttachGpu', obj)
              //     ret.tooltip = cloudUnabledTip('acttachGpu', obj)
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_set_gpu'),
              // },
              // // 设置USB透传
              // {
              //   label: i18n.t('compute.text_1399'),
              //   permission: 'server_perform_set_isolated_device,attach-isolated-device,server_perform_detach_isolated_device',
              //   action: () => {
              //     this.createDialog('VmAttachUsbDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (!this.isAdminMode && !this.isDomainMode) {
              //       ret.tooltip = i18n.t('compute.text_1279', [i18n.t('dictionary.domain')])
              //       return ret
              //     }
              //     if (findPlatform(obj.hypervisor, 'hypervisor') !== SERVER_TYPE.idc) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     ret.validate = cloudEnabled('acttachUsb', obj)
              //     ret.tooltip = cloudUnabledTip('acttachUsb', obj)
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_set_usb'),
              // },
              // // 修改启动顺序
              // {
              //   label: i18n.t('compute.change_boot_index'),
              //   permission: 'server_perform_set_boot_index',
              //   action: () => {
              //     this.createDialog('VmChangeBootIndexDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: (row) => {
              //     const isOneCloud = row.brand === 'OneCloud'
              //     const provider = obj.provider
              //     return {
              //       validate: isOneCloud,
              //       tooltip: !isOneCloud && i18n.t('compute.text_473', [PROVIDER_MAP[provider].label]),
              //     }
              //   },
              //   hidden: (row) => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_set_boot_index'),
              // },
              // // 更换块存储
              // {
              //   label: i18n.t('compute.vminstance.change_disk_storage'),
              //   permission: 'server_perform_change_storage',
              //   action: () => {
              //     this.createDialog('VmChangeBlockStorageDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       refresh: this.refresh,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: true,
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     return {
              //       validate: cloudEnabled('changeBlockStorage', obj),
              //       tooltip: cloudUnabledTip('changeBlockStorage', obj),
              //     }
              //   },
              //   hidden: () => !hasSetupKey(['onecloud']) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_change_disk_storage'),
              // },
              // // 绑定物理CPU
              // {
              //   label: i18n.t('compute.bind_physical_cpu'),
              //   permission: 'server_perform_cpuset',
              //   action: () => {
              //     this.createDialog('BindPhysicalCpuDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       alert: i18n.t('compute.text_1391'),
              //       refresh: this.refresh,
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[obj.provider].label])
              //       return ret
              //     }
              //     return {
              //       ...ret,
              //       validate: true,
              //     }
              //   },
              //   hidden: () => !hasSetupKey(['onecloud']),
              // },
              // // 设置磁盘速度
              // {
              //   label: i18n.t('compute.text_1249'),
              //   permission: 'server_perform_io_throttle',
              //   action: () => {
              //     this.createDialog('VmSetSpeedDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     if (obj.status !== 'running') {
              //       ret.tooltip = i18n.t('compute.text_1282')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_set_disk_speed'),
              // },
            ],
          },
          // * 密码密钥
          {
            label: i18n.t('compute.text_360'),
            submenus: [
              // 重置密码
              {
                label: i18n.t('compute.text_276'),
                permission: 'server_perform_deploy',
                action: () => {
                  this.createDialog('VmResetPasswordDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (commonUnabled(obj)) return ret
                  if (obj.keypair_id && obj.keypair_id.toLowerCase() !== 'none') {
                    ret.tooltip = i18n.t('compute.text_277')
                    return ret
                  }
                  ret.validate = cloudEnabled('resetPassword', obj)
                  ret.tooltip = cloudUnabledTip('resetPassword', obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_reset_password'),
              },
              // 绑定密钥
              {
                label: i18n.t('compute.text_361'),
                permission: 'server_perform_deploy',
                action: () => {
                  this.createDialog('VmBindKeypairDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (obj.hypervisor === typeClouds.hypervisorMap.openstack.key) {
                    ret.tooltip = i18n.t('compute.text_1284')
                    return ret
                  }
                  if (obj.os_type === 'Windows') {
                    ret.tooltip = i18n.t('compute.text_1285')
                    return ret
                  }
                  const osType = obj.metadata && obj.metadata.os_name
                  if (['aws', 'azure', 'google', 'aliyun'].includes(obj.hypervisor) && osType === 'Windows') {
                    ret.tooltip = i18n.t('compute.text_1285')
                    return ret
                  }
                  if (commonUnabled(obj)) return ret
                  if (obj.keypair) {
                    ret.tooltip = i18n.t('compute.text_363')
                    return ret
                  }
                  ret.validate = cloudEnabled('bindKeyPair', obj)
                  ret.tooltip = cloudUnabledTip('bindKeyPair', obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_bind_key') ||
                obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              },
              // 解绑密钥
              {
                label: i18n.t('compute.text_364'),
                permission: 'server_perform_deploy',
                action: () => {
                  this.createDialog('VmUnbindKeypairDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (commonUnabled(obj)) return ret
                  if (!obj.keypair) {
                    ret.tooltip = i18n.t('compute.text_365')
                    return ret
                  }
                  ret.validate = cloudEnabled('unBindKeyPair', obj)
                  ret.tooltip = cloudUnabledTip('unBindKeyPair', obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_unbind_key'),
              },
              // 设置免密登录
              {
                label: i18n.t('compute.vminstance.actions.setup_ssh_authentication'),
                permission: 'server_perform_setup_ssh_proxy',
                action: () => {
                  this.createDialog('SetupSSHDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: true,
                    tooltip: null,
                  }
                  const isLinux = obj.os_type && obj.os_type.toLowerCase() === 'linux'
                  if (!isLinux) {
                    ret.validate = false
                    ret.tooltip = i18n.t('compute.text_362')
                    return ret
                  }
                  if (!commonEnabled(obj, ['running'])) {
                    ret.validate = false
                    ret.tooltip = i18n.t('db.text_156')
                    return ret
                  }
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_setup_ssh_proxy'),
              },
              // 探测免密登录
              {
                label: i18n.t('compute.vminstance.actions.detect_ssh_authentication'),
                permission: 'server_perform_make_sshable',
                action: () => {
                  this.createDialog('DetectSSHDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: true,
                    tooltip: null,
                  }
                  if (!commonEnabled(obj, ['running'])) {
                    ret.validate = false
                    ret.tooltip = i18n.t('db.text_156')
                    return ret
                  }
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_detect_ssh_proxy'),
              },
            ],
          },
          // * 镜像与备份
          {
            label: i18n.t('compute.group_action.mirror_backup'),
            submenus: [
              // 保存镜像
              {
                label: i18n.t('compute.text_1236'),
                permission: 'server_perform_save_image',
                action: () => {
                  this.createDialog('VmSaveImageDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  // if (findPlatform(obj.hypervisor) === SERVER_TYPE.public) {
                  //   ret.tooltip = i18n.t('compute.text_1286')
                  //   return ret
                  // }
                  const noSupportBrand = [
                    typeClouds.hypervisorMap.openstack.brand,
                    typeClouds.hypervisorMap.zstack.brand,
                    typeClouds.hypervisorMap.dstack.brand,
                    typeClouds.hypervisorMap.ucloud.brand,
                    typeClouds.hypervisorMap.ctyun.brand,
                    typeClouds.hypervisorMap.nutanix.brand,
                    typeClouds.hypervisorMap.proxmox.brand,
                  ]
                  if (noSupportBrand.includes(obj.brand)) {
                    ret.tooltip = i18n.t('compute.text_1287', [obj.brand])
                    return ret
                  }
                  if (commonUnabled(obj)) return ret
                  ret.validate = commonEnabled(obj)
                  ret.tooltip = commonTip(obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_save_image'),
              },
              // 挂载ISO
              {
                label: i18n.t('compute.text_366'),
                permission: 'server_perform_insertiso',
                action: () => {
                  this.createDialog('VmMountIsoDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    refresh: this.refresh,
                  })
                },
                meta: () => {
                  // const provider = obj.provider
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  // if (obj.hypervisor === typeClouds.hypervisorMap.esxi.key) {
                  //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
                  //   return ret
                  // }
                  // if (findPlatform(obj.hypervisor) === SERVER_TYPE.public) {
                  //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
                  //   return ret
                  // }
                  if (commonUnabled(obj)) return ret
                  if (obj.cdrom) {
                    ret.tooltip = i18n.t('compute.text_1288')
                    return ret
                  }
                  ret.validate = cloudEnabled('insertiso', obj)
                  ret.tooltip = cloudUnabledTip('insertiso', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['vmware', 'onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_mount_iso'),
              },
              // 卸载ISO
              {
                label: i18n.t('compute.text_367'),
                permission: 'server_perform_ejectiso',
                action: () => {
                  this.createDialog('VmUnmountIsoDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    refresh: this.refresh,
                  })
                },
                meta: () => {
                  // const provider = obj.provider
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  // if (obj.hypervisor === typeClouds.hypervisorMap.esxi.key) {
                  //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
                  //   return ret
                  // }
                  // if (findPlatform(obj.hypervisor) === SERVER_TYPE.public) {
                  //   ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
                  //   return ret
                  // }
                  if (commonUnabled(obj)) return ret
                  if (!obj.cdrom) {
                    ret.tooltip = i18n.t('compute.text_1289')
                    return ret
                  }
                  ret.validate = cloudEnabled('ejectiso', obj)
                  ret.tooltip = cloudUnabledTip('ejectiso', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['vmware', 'onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_unmount_iso'),
              },
              // 创建快照
              {
                label: i18n.t('compute.text_1276'),
                permission: 'snapshots_create,server_perform_instance_backup',
                action: async () => {
                  const snapshotResult = await this.$http.get('/v3/resource_bill_snapshot_prices/snapshot-disable', {
                    params: {
                      provider: obj.provider,
                      area_id: obj.area_mapping_id,
                      billing_type: obj.billing_type,
                    },
                  })
                  // console.log('更多==============', obj, snapshotResult.data.disabled)
                  if (snapshotResult.data.disabled) {
                    // 提示不可用
                    // this.$message.error('该资源或资源所在地域暂不支持创建快照')
                    this.$message.error('抱歉，当前设备或资源不满足快照条件')
                  } else {
                    this.$openNewWindowForMenuHook('vminstance_configured_callback_address.create_snapshot_callback_address', () => {
                      this.createDialog('VmSnapshotCreateDialog', {
                        data: [obj],
                        specs: snapshotResult.data.specs,
                        columns: this.columns,
                        onManager: this.onManager,
                        refresh: this.refresh,
                      })
                    })
                  }
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (obj.is_prepaid_recycle) {
                    ret.tooltip = i18n.t('compute.text_285')
                    return ret
                  }
                  if (obj.backup_host_id) {
                    ret.tooltip = i18n.t('compute.text_1277')
                    return ret
                  }
                  if (commonUnabled(obj)) return ret
                  ret.validate = cloudEnabled('createSnapshot', obj)
                  ret.tooltip = cloudUnabledTip('createSnapshot', obj)
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_create_snapshot'),
              },
              // 创建备份
              // {
              //   label: i18n.t('compute.create_disk_backup2'),
              //   permission: 'server_perform_instance_backup,diskbackups_create,instancebackups_create',
              //   action: () => {
              //     this.createDialog('VmBackupCreateDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       refresh: this.refresh,
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.is_prepaid_recycle) {
              //       ret.tooltip = i18n.t('compute.text_285')
              //       return ret
              //     }
              //     if (obj.backup_host_id) {
              //       ret.tooltip = i18n.t('compute.text_1277')
              //       return ret
              //     }
              //     if (commonUnabled(obj)) return ret
              //     ret.validate = cloudEnabled('createBackup', obj)
              //     ret.tooltip = cloudUnabledTip('createBackup', obj)
              //     return ret
              //   },
              //   hidden: () => !hasSetupKey(['onecloud']) || obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
            ],
          },
          // * 网络与安全
          {
            label: i18n.t('compute.text_1290'),
            submenus: [
              // 修改安全组
              // {
              //   label: i18n.t('compute.text_1116'),
              //   permission: 'server_perform_add_secgroup',
              //   action: () => {
              //     this.createDialog('VmSetSecgroupDialog', {
              //       vm: this,
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       refresh: this.refresh,
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: cloudEnabled('assignSecgroup', obj),
              //       tooltip: cloudUnabledTip('assignSecgroup', obj),
              //     }
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onestack', 'onecloud', 'public', 'openstack', 'dstack', 'zstack', 'apsara', 'cloudpods', 'hcso', 'hcs'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_add_secgroup'),
              // },
              // 绑定eip
              {
                label: i18n.t('compute.text_1179'),
                permission: 'server_perform_create_eip',
                action: () => {
                  this.createDialog('VmBindEipDialog', {
                    data: [obj],
                    columns: this.columns,
                    refresh: this.refresh,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (commonUnabled(obj)) return ret
                  if (obj.eip_mode === 'public_ip' && obj.hypervisor !== 'aws') {
                    ret.tooltip = i18n.t('compute.public_ip_tooltip')
                    return ret
                  }
                  if (obj.eip_mode !== 'public_ip' && obj.eip) {
                    ret.tooltip = i18n.t('compute.text_1291')
                    return ret
                  }
                  if (obj.brand === 'OneCloud' && obj.vpc_id === 'default') {
                    ret.tooltip = i18n.t('compute.text_1292')
                    return ret
                  }
                  if (obj.vpc_external_access_mode === 'none') {
                    ret.tooltip = i18n.t('compute.disable_bind_eip')
                    return ret
                  }
                  ret.validate = cloudEnabled('bindEip', obj)
                  ret.tooltip = cloudUnabledTip('bindEip', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['onestack', 'onecloud', 'public', 'openstack', 'dstack', 'zstack', 'apsara', 'cloudpods', 'hcso', 'hcs'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_bind_elastic_public_ip'),
              },
              // 解绑eip
              {
                label: i18n.t('compute.text_1264'),
                permission: 'server_perform_dissociate_eip',
                action: () => {
                  this.createDialog('VmUnbindEipDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    refresh: this.refresh,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (commonUnabled(obj)) return ret
                  if (obj.eip_mode !== 'elastic_ip') {
                    ret.tooltip = i18n.t('compute.text_1293')
                    return ret
                  }
                  if (obj.eip_mode === 'public_ip') {
                    ret.tooltip = i18n.t('compute.text_1294')
                    return ret
                  }
                  ret.validate = cloudEnabled('unbindEip', obj)
                  ret.tooltip = cloudUnabledTip('unbindEip', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['onestack', 'onecloud', 'public', 'openstack', 'dstack', 'zstack', 'apsara', 'cloudpods', 'hcso', 'hcs'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_unbind_elastic_public_ip'),
              },
              // 公网IP转EIP
              {
                label: i18n.t('compute.text_1121'),
                permission: 'server_perform_publicip_to_eip',
                action: () => {
                  this.createDialog('VmPublicIpToEipDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    refresh: this.refresh,
                  })
                },
                meta: () => {
                  const ret = {
                    validate: false,
                    tooltip: null,
                  }
                  if (obj.eip && obj.eip_mode === 'elastic_ip') {
                    ret.tooltip = i18n.t('compute.text_1122')
                    return ret
                  }
                  if (obj.eip_mode !== 'public_ip') {
                    ret.tooltip = i18n.t('compute.text_1123')
                    return ret
                  }
                  ret.validate = cloudEnabled('publicIpToEip', obj)
                  ret.tooltip = cloudUnabledTip('publicIpToEip', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['aliyun', 'qcloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_public_ip_to_eip'),
              },
              // 设置源/目标检查
              // {
              //   label: i18n.t('compute.text_1124'),
              //   permission: 'server_perform_modify_src_check',
              //   action: () => {
              //     this.createDialog('VmSourceTargetCheckDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //       refresh: this.refresh,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = { validate: true, tooltip: null }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     if (!['running', 'ready'].includes(obj.status)) {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_1126')
              //       return ret
              //     }
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_set_source_check') ||
              //   obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
            ],
          },
          // * 高可用
          {
            label: i18n.t('compute.text_1295'),
            submenus: [
              // 添加备份机
              // {
              //   label: i18n.t('compute.text_1162'),
              //   permission: 'server_perform_create_backup',
              //   action: () => {
              //     this.createDialog('VmAddBackupDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     if (!['ready'].includes(obj.status)) {
              //       ret.tooltip = i18n.t('compute.text_1308')
              //       return ret
              //     }
              //     if (obj.backup_host_id) {
              //       ret.tooltip = i18n.t('compute.text_1296')
              //       return ret
              //     }
              //     if (obj.is_gpu) {
              //       ret.tooltip = i18n.t('compute.gpu_not_support_add_host')
              //       return ret
              //     }
              //     if (!this.isAdminMode && !this.isDomainMode) {
              //       ret.tooltip = i18n.t('migration.project.error')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_add_backup') ||
              //   obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
              // 删除备份机
              // {
              //   label: i18n.t('compute.text_1209'),
              //   permission: 'server_perform_delete_backup',
              //   action: () => {
              //     this.createDialog('VmDeleteBackupDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const provider = obj.provider
              //     const ret = {
              //       validate: false,
              //       tooltip: null,
              //     }
              //     if (!obj.backup_host_id) {
              //       ret.tooltip = i18n.t('compute.text_1383')
              //       return ret
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[provider].label])
              //       return ret
              //     }
              //     if (!this.isAdminMode && !this.isDomainMode) {
              //       ret.tooltip = i18n.t('migration.project.error')
              //       return ret
              //     }
              //     ret.validate = true
              //     return ret
              //   },
              //   hidden: () => !(hasSetupKey(['onecloud'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_delete_backup') ||
              //    obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
              // 迁移
              {
                label: i18n.t('compute.text_1127'),
                permission: 'server_perform_migrate,server_perform_live_migrate',
                action: () => {
                  this.createDialog('VmTransferDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  const ret = { validate: true, tooltip: null }
                  if (!this.isAdminMode && !this.isDomainMode) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_domain_permission')
                    return ret
                  }
                  if (!['running', 'ready'].includes(obj.status)) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_status_transfer')
                    return ret
                  }
                  if (obj.backup_host_id) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_backup_host_transfer')
                    return ret
                  }
                  if (obj.is_gpu) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_gpu_transfer')
                    return ret
                  }
                  if (obj.cdrom) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_cdrom_transfer')
                    return ret
                  }
                  ret.validate = cloudEnabled('transfer', obj)
                  // ret.tooltip = cloudUnabledTip('transfer', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['openstack', 'onecloud', 'vmware'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_transfer'),
              },
              // V2V迁移
              {
                label: i18n.t('compute.v2vtransfer.label'),
                permission: 'server_perform_migrate',
                action: () => {
                  this.createDialog('VmV2vTransferDialog', {
                    data: [obj],
                    columns: this.columns,
                    onManager: this.onManager,
                    successCallback: this.refresh,
                  })
                },
                meta: () => {
                  const ret = { validate: true, tooltip: null }

                  if (!this.isAdminMode && !this.isDomainMode) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_domain_permission')
                    return ret
                  }
                  if (![typeClouds.hypervisorMap.cloudpods.key, typeClouds.hypervisorMap.esxi.key].includes(obj.hypervisor)) {
                    ret.validate = false
                    ret.tooltip = i18n.t('compute.brand_support', [`${typeClouds.hypervisorMap.cloudpods.label},${typeClouds.hypervisorMap.esxi.label}`])
                    return ret
                  }
                  if (!['ready'].includes(obj.status)) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_ready_status_transfer')
                    return ret
                  }
                  if (obj.backup_host_id) {
                    ret.validate = false
                    ret.tooltip = this.$t('compute.tooltip.check_backup_host_transfer')
                    return ret
                  }

                  ret.validate = cloudEnabled('v2vTransfer', obj)
                  ret.tooltip = cloudUnabledTip('v2vTransfer', obj)
                  return ret
                },
                hidden: () => !(hasSetupKey(['vmware'])) || this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_transfer'),
              },
              // 快速恢复
              // {
              //   label: i18n.t('compute.server.quick.recovery'),
              //   action: () => {
              //     this.createDialog('VmQuickRecoveryDialog', {
              //       data: [obj],
              //       columns: this.columns,
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: () => {
              //     const ret = {
              //       validate: true,
              //       tooltip: '',
              //     }
              //     if (obj.hypervisor !== typeClouds.hypervisorMap.kvm.key) {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.text_473', [PROVIDER_MAP[obj.provider].label])
              //       return ret
              //     }
              //     if (obj.host_service_status !== 'offline') {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.quick.recovery.validate.host_status_tooltip')
              //       return ret
              //     }
              //     const isAllKVMShareStorages = obj.disks_info.every(item => KVM_SHARE_STORAGES.includes(item.storage_type))
              //     if (!isAllKVMShareStorages) {
              //       ret.validate = false
              //       ret.tooltip = i18n.t('compute.quick.recovery.validate.host_status_tooltip')
              //       return ret
              //     }
              //     return ret
              //   },
              //   hidden: () => !hasSetupKey(['onecloud']) || obj.hypervisor === HYPERVISORS_MAP.mgecloud.key,
              // },
            ],
          },
          // * 删除
          {
            label: i18n.t('compute.perform_delete'),
            submenus: [
              // 设置删除保护
              disableDeleteAction(Object.assign(this, {
                permission: 'server_update',
              }), {
                name: i18n.t('dictionary.server'),
                meta: () => {
                  const ret = { validate: true }
                  if (obj.hypervisor === typeClouds.hypervisorMap.bingocloud.key) {
                    ret.tooltip = i18n.t('compute.text_473', [typeClouds.hypervisorMap.bingocloud.label])
                    ret.validate = false
                    return ret
                  }
                  return ret
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_set_delete_protection'),
              }),
              // 删除
              {
                label: i18n.t('compute.perform_delete'),
                permission: 'server_delete',
                action: () => {
                  this.$openNewWindowForMenuHook('vminstance_configured_callback_address.delete_callback_address', () => {
                    this.createDialog('DeleteVmDialog', {
                      vm: this,
                      data: [obj],
                      columns: this.columns,
                      onManager: this.onManager,
                      title: i18n.t('compute.perform_delete'),
                      success: () => {
                        this.destroySidePages()
                      },
                    })
                  })
                },
                meta: () => {
                  const ret = { validate: false }
                  if (obj.hypervisor === typeClouds.hypervisorMap.bingocloud.key) {
                    ret.tooltip = i18n.t('compute.text_473', [typeClouds.hypervisorMap.bingocloud.label])
                    return ret
                  }
                  const { server_delete_limit = false } = this.$store.getters.globalSetting.value || {}
                  if (server_delete_limit && obj.status === 'running') {
                    ret.tooltip = i18n.t('compute.delete_limit')
                    return ret
                  }
                  return this.$getDeleteResult(obj)
                },
                hidden: () => this.$isScopedPolicyMenuHidden('vminstance_hidden_menus.server_perform_delete'),
              },
            ],
          },
        ]
      },
      meta: (obj) => {
        let ret = {
          validate: true,
          tooltip: null,
        }
        ret = this.$isValidateResourceLock(obj)
        return ret
      },
    },
  ]
}
export default {
  name: POLICY_RES_NAME_KEY_MAP.vminstance.key,
  getSingleActions,
}
