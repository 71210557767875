<template>
  <base-dialog @cancel="cancelDialog" :width="dialogWidth">
    <div slot="header">定价设置</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <span class="info-title">基础信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="资源厂商">
              <span>{{ params.row.provider_name }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="资源类型">
              <span>{{ params.row.product_type_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="资源">
              <span>{{ params.row.product_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="info-title">定价信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="计费方式">
              <span class="charge-type">
                {{ this.getDictDataLabel(DICT_TYPE.CHARGE_TYPE,params.row.charge_type) }}
                <a-select v-model="eipPostpaidType" @change="handleEipPostpaidTypeChange" v-if="showEipPostpaidType" style="width: 100px;">
                  <a-select-option v-for="item of eipPostpaidOpts" :key="item.value" :value="item.value">
                    {{item.label}}
                  </a-select-option>
                </a-select>
                <a-select v-model="slbPostpaidType" @change="handleSlbPostpaidTypeChange" v-if="showSlbPostpaidType" style="width: 100px;">
                  <a-select-option v-for="item of slbPostpaidOpts" :key="item.value" :value="item.value">
                    {{item.label}}
                  </a-select-option>
                </a-select>
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item label="整体定价">
              <span>芒果云价格x <a-input-number v-model="rate" /> 倍</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="showExtField">
          <a-col :span="24">
            <span class="info-title" v-if="slbPostpaidType === 'lcu'">定价表-实例、LCU</span>
            <span class="info-title" v-else>定价表-实例</span>
          </a-col>
        </a-row>
        <a-row v-if="showExtField">
          <a-col :span="12">
            <a-form-item label="芒果云价格（实例）">
              <span>
                <a-input-number
                v-model="basePriceData.instance_price"
                :precision="4"
                :min="0" />
                /小时
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="slbPostpaidType === 'lcu'">
            <a-form-item label="芒果云价格（LCU）">
              <span>
                <a-input-number
                v-model="basePriceData.lcu_price"
                :precision="4"
                :min="0" />
                /个/小时
              </span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <page-body :style="{width: (dialogWidth - 70) + 'px'}">
        <template v-if="!isEipPrepaid">
          <a-row>
            <a-col :span="12">
              <a-form-item :label="'定价表' + table_title_suffix" />
            </a-col>
          </a-row>
          <page-list
            :list="list"
            :columns="columns"
            :showSearchbox="false"
            :showSingleActions="false"
            :showGroupActions="false" />
        </template>
        <template v-if="showExtTable">
          <a-row>
            <a-col :span="12">
                <a-form-item :label="'定价表' + ext_table_title_suffix" />
              </a-col>
            </a-row>
            <page-list
              :list="extList"
              :columns="extColumns"
              :showSearchbox="false"
              :showSingleActions="false"
              :showGroupActions="false" />
        </template>
      </page-body>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认定价</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ListMixin from '@/mixins/list'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { STORAGE_TYPES } from '@Compute/constants'
import { HYPERVISORS_MAP } from '@/constants'

export default {
  name: 'ProductPricingUpdateDialog',
  mixins: [ListMixin, DialogMixin, WindowsMixin],
  data () {
    return {
      list: this.$list.createList(this, {
        id: 'ProductPricingUpdateList',
        idKey: 'only_tag',
        resource: this.params.resource,
        apiVersion: 'v3',
        getParams: this.getParam,
        responseData: this.responseData,
      }),
      extList: this.$list.createList(this, {
        id: 'ProductPricingUpdateExtList',
        idKey: 'only_tag',
        resource: this.params.extResource,
        apiVersion: 'v3',
        getParams: this.getParam,
        responseData: this.responseData,
      }),
      rate: 1,
      loading: false,
      form: {
        fc: this.$form.createForm(this),
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
      table_title_suffix: '',
      productColumns: [],
      priceColumns: [],
      price_title_suffix: '',
      price_unit: '',
      showExtTable: false,
      ext_table_title_suffix: '',
      extProductColumns: [],
      extPriceColumns: [],
      ext_price_title_suffix: '',
      ext_price_unit: '',
      showEipPostpaidType: false,
      eipPostpaidOpts: [
        { label: '按流量', value: 'traffic' },
        { label: '按带宽', value: 'bandwidth' },
      ],
      eipPostpaidType: 'traffic',
      showSlbPostpaidType: false,
      slbPostpaidOpts: [
        { label: '按使用量', value: 'lcu' },
        { label: '按规格', value: 'spec' },
      ],
      slbPostpaidType: 'lcu',
      showExtField: false,
      basePriceData: {},
      dialogWidth: 1000,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    columns () {
      return [
        {
          field: 'enabled',
          title: '上架',
          align: 'center',
          width: 80,
          slots: {
            default: ({ row }) => {
              return [
                <a-switch
                  v-model={row.enabled}
                  disabled={this.getShelfEnabled}
                />,
              ]
            },
          },
        },
        {
          field: 'mapping_name',
          title: '地域',
          align: 'center',
          width: 130,
          slots: {
            default: ({ row }) => {
              return [<span>{row.mapping_name}</span>]
            },
          },
        },
        ...this.productColumns,
        {
          field: 'price',
          title: '芒果云价格' + this.price_title_suffix,
          align: 'center',
          width: 180,
          slots: {
            default: ({ row }) => {
              // return [<span>￥{numerify(row.first_price, '0,0.00')}{this.price_unit}</span>]
              return [
                <a-input-number
                  v-model={row.price}
                  precision={4}
                  min={0}
                />,
                <span>{this.price_unit}</span>,
              ]
            },
          },
        },
        ...this.priceColumns,
      ]
    },
    extColumns () {
      return [
        {
          field: 'enabled',
          title: '上架',
          align: 'center',
          width: 80,
          slots: {
            default: ({ row }) => {
              return [
                <a-switch
                  v-model={row.enabled}
                  disabled={this.getShelfEnabled}
                />,
              ]
            },
          },
        },
        {
          field: 'mapping_name',
          title: '地域',
          align: 'center',
          width: 120,
          slots: {
            default: ({ row }) => {
              return [<span>{row.mapping_name}</span>]
            },
          },
        },
        ...this.extProductColumns,
        {
          field: 'price',
          title: '芒果云价格' + this.ext_price_title_suffix,
          align: 'center',
          width: 190,
          slots: {
            default: ({ row }) => {
              // return [<span>￥{numerify(row.first_price, '0,0.00')}{this.price_unit}</span>]
              return [
                '￥',
                <a-input-number
                  v-model={row.price}
                  precision={4}
                  min={0}
                />,
                <span>{this.ext_price_unit}</span>,
              ]
            },
          },
        },
        ...this.extPriceColumns,
      ]
    },
    isEipPrepaid () {
      const { charge_type, product_code } = this.params.row
      return charge_type === 'prepaid' && product_code === 'eip'
    },
    // 获取上下架状态, true为上架
    getShelfEnabled () {
      return !this.params.row.enabled || !this.params.row.product_status
    },
  },
  async created () {
    console.log('this.params', { ...this.params })
    this.setColumns()
    await this.$nextTick()
    if (!this.isEipPrepaid) {
      this.list.fetchData()
    }
    this.showExtTable && this.extList.fetchData()
    if (!this.showExtField) return
    this.getBasePriceList()
  },
  methods: {
    // 获取实例、LCU基础价格
    async getBasePriceList () {
      this.basePriceData = {}
      const url = this.params.basePriceResource
      const { data } = await this.$http.get(url, { params: this.getParam() })
      this.basePriceData = data.data
      this.basePriceData.instance_price = data.data.instance_price || 0
      this.basePriceData.lcu_price = data.data.lcu_price || 0
    },
    // 设置实例、LCU基础价格
    setBasePriceList () {
      const url = '/v2/resource_bill_slb_lcu_prices/save-base-price'
      return this.$http.post(url, this.basePriceData)
    },
    setColumns () {
      this.resetState()
      const product_code = this.params.row.product_code
      const charge_type = this.params.row.charge_type
      const gpuColumns = [
        {
          field: 'device_model',
          title: 'GPU',
          slots: {
            default: ({ row }) => {
              return (row.device_model && row.gpu_count) ? row.device_model + '*' + row.gpu_count : '-'
            },
          },
        },
        {
          field: 'gpu_memory',
          title: 'GPU显存',
          slots: {
            default: ({ row }) => {
              return row.gpu_memory ? (+row.gpu_memory) / 1024 + 'GB' : '-'
            },
          },
        },
      ]
      switch (product_code) {
        case 'ecs':
          if (charge_type === 'prepaid') {
            this.price_title_suffix = '(实例)'
            this.price_unit = '/月'
          } else {
            this.price_title_suffix = '(实例)'
            this.price_unit = '/小时'
          }
          this.productColumns = [
            {
              field: 'instance_type_category',
              title: '规格类型',
              align: 'center',
              width: 130,
            },
            // {
            //   field: 'name',
            //   width: 130,
            //   title: '实例',
            // },
            {
              field: 'cpu_core_count',
              width: 110,
              align: 'center',
              title: 'vCPU',
              slots: {
                default: ({ row }) => {
                  return [<span>{row.cpu_core_count} vCPU</span>]
                },
              },
            },
            {
              field: 'memory_size_mb',
              width: 110,
              align: 'center',
              title: '内存',
              slots: {
                default: ({ row }) => {
                  return [<span>{(+row.memory_size_mb) / 1024} GiB</span>]
                },
              },
            },
            {
              field: 'cpu_arch',
              width: 110,
              align: 'center',
              title: '架构',
            },
          ]
          if (HYPERVISORS_MAP.cloudpods.key === this.params.row.provider.toLowerCase()) {
            this.dialogWidth = 1200
            this.productColumns.push(...gpuColumns)
          }
          break
        case 'ebs':
        case 'disk':
          if (charge_type === 'prepaid') {
            this.price_title_suffix = ''
            this.price_unit = 'Gib/月'
          } else {
            this.price_title_suffix = ''
            this.price_unit = 'Gib/小时'
          }
          this.productColumns = [
            {
              field: 'ebs_type',
              title: '规格类型',
              align: 'center',
              // width: 130,
              slots: {
                default: ({ row }) => {
                  const types = STORAGE_TYPES
                  const item = types[this.params.row.provider.toLowerCase()][row.ebs_type]
                  return [
                    <span>{item?.label || '-'}</span>,
                  ]
                },
              },
            },
          ]
          break
        case 'snapshot':
          this.price_title_suffix = ''
          this.price_unit = 'Gib/小时'
          this.productColumns = [
            {
              field: 'name',
              title: '磁盘类型',
              align: 'center',
              width: 130,
            },
          ]
          break
        case 'eip':
          if (charge_type === 'prepaid') {
            // 只有第二张表
            // this.price_title_suffix = '(1M-5M)'
            this.ext_price_unit = '/Mbps/月'
            this.ext_price_title_suffix = '(1M-5M)'
            this.showExtTable = true
            this.extProductColumns = [
              {
                field: 'sku',
                title: '线路类型',
                align: 'center',
                width: 130,
              },
              {
                field: 'network',
                title: '网络类型',
                align: 'center',
                width: 100,
              },
            ]
            this.extPriceColumns = [
              {
                field: 'bandwidth_above_5m_price',
                title: '芒果云价格(>5M)',
                align: 'center',
                // width: 220,
                slots: {
                  default: ({ row }) => {
                    return [
                      <span>{`￥ ${row.price} x5+(n-5)x`}</span>,
                      <a-input-number
                        v-model={row.bandwidth_above_5m_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/Mbps/月</span>,
                    ]
                  },
                },
              },
            ]
          } else {
            this.showEipPostpaidType = true
            this.eipPostpaidType = 'traffic'
            this.table_title_suffix = '-ip地址'
            this.price_title_suffix = '(公网IP地址单价)'
            this.price_unit = '/小时/个'
            this.productColumns = []
            this.priceColumns = []
            // 第二张表
            this.showExtTable = true
            this.ext_table_title_suffix = '-流量'
            this.ext_price_title_suffix = '(流量)'
            this.ext_price_unit = '/GB'
            this.extProductColumns = [
              {
                field: 'sku',
                title: '线路类型',
                align: 'center',
                width: 130,
              },
            ]
            this.extPriceColumns = []
          }
          break
        case 'slb':
          this.showExtField = true
          if (charge_type === 'prepaid') {
            this.table_title_suffix = '-规格、实例'
            this.price_title_suffix = '(规格)'
            this.price_unit = '/月'
            this.productColumns = [
              {
                field: 'instance_type_category',
                title: '规格类型',
                align: 'center',
                width: 130,
              },
            ]
            this.priceColumns = [
              {
                field: 'm5_price',
                title: '芒果云价格(实例)',
                align: 'center',
                width: 180,
                slots: {
                  default: ({ row }) => {
                    return [
                      <a-input-number
                        v-model={row.m5_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/月</span>,
                    ]
                  },
                },
              },
            ]

            // 第二张表
            this.showExtTable = true
            this.ext_table_title_suffix = '-带宽'
            this.ext_price_title_suffix = '(1M)'
            this.ext_price_unit = '/GB'
            this.extProductColumns = [
              {
                field: 'instance_type_category',
                title: '规格类型',
                align: 'center',
                width: 130,
              },
            ]
            this.extPriceColumns = [
              {
                field: 'm2_price',
                title: '芒果云价格(2M)',
                align: 'center',
                slots: {
                  default: ({ row }) => {
                    return [
                      <a-input-number
                        v-model={row.m2_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/月</span>,
                    ]
                  },
                },
              },
              {
                field: 'm3_price',
                title: '芒果云价格(3M)',
                align: 'center',
                slots: {
                  default: ({ row }) => {
                    return [
                      <a-input-number
                        v-model={row.m3_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/月</span>,
                    ]
                  },
                },
              },
              {
                field: 'm4_price',
                title: '芒果云价格(4M)',
                align: 'center',
                slots: {
                  default: ({ row }) => {
                    return [
                      <a-input-number
                        v-model={row.m4_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/月</span>,
                    ]
                  },
                },
              },
              {
                field: 'm5_price',
                title: '芒果云价格(5M)',
                align: 'center',
                slots: {
                  default: ({ row }) => {
                    return [
                      <span>{`￥ ${row.value} x5+(n-5)x`}</span>,
                      <a-input-number
                        v-model={row.m5_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/月</span>,
                    ]
                  },
                },
              },
            ]
          } else {
            this.showSlbPostpaidType = true
            this.slbPostpaidType = 'lcu'
            this.table_title_suffix = '-流量'
            this.price_title_suffix = '(流量)'
            this.price_unit = '/GB'
            this.productColumns = []
            this.priceColumns = []
            // 第二张表
            this.showExtTable = false
          }
          break
        case 'nat_gateway':
          if (charge_type === 'prepaid') {
            this.price_title_suffix = ''
            this.price_unit = ''
          } else {
            this.price_title_suffix = '(实例)'
            this.price_unit = '/小时'
          }
          this.productColumns = [
            {
              field: 'sku_name',
              title: '规格类型',
              align: 'center',
              width: 130,
            },
          ]
          if (HYPERVISORS_MAP.mgecloud.key === this.params.row.provider.toLowerCase()) {
            this.priceColumns = []
          } else {
            this.priceColumns = [
              {
                field: 'cu_price',
                title: '芒果云价格(CU)',
                align: 'center',
                width: 180,
                slots: {
                  default: ({ row }) => {
                    // return [<span>￥{numerify(row.first_price, '0,0.00')}{this.price_unit}</span>]
                    return [
                      <a-input-number
                        v-model={row.cu_price}
                        precision={4}
                        min={0}
                      />,
                      <span>/个/小时</span>,
                    ]
                  },
                },
              },
            ]
          }
          break
        default:
          break
      }
    },
    resetState () {
      this.showEipPostpaidType = false
      this.showSlbPostpaidType = false
      this.showExtField = false

      this.table_title_suffix = ''
      this.price_title_suffix = ''
      this.price_unit = ''
      this.productColumns = []
      this.priceColumns = []

      this.showExtTable = false
      this.ext_table_title_suffix = ''
      this.ext_price_title_suffix = ''
      this.ext_price_unit = ''
      this.extProductColumns = []
      this.extPriceColumns = []
    },
    handleEipPostpaidTypeChange (val) {
      console.log(val)
      if (val === 'traffic') {
        this.table_title_suffix = '-ip地址'
        this.price_title_suffix = '(公网IP地址单价)'
        this.price_unit = '/小时/个'
        this.productColumns = []
        this.priceColumns = []
        this.ext_table_title_suffix = '-流量'
        this.ext_price_title_suffix = '(流量)'
        this.ext_price_unit = '/GB'
        this.extProductColumns = [
          {
            field: 'sku',
            title: '线路类型',
            align: 'center',
            width: 130,
          },
        ]
        this.extPriceColumns = []
      } else {
        this.table_title_suffix = '-ip地址'
        this.price_title_suffix = '(公网IP地址单价)'
        this.price_unit = '/天/个'
        this.productColumns = []
        this.priceColumns = []
        this.ext_table_title_suffix = '-带宽'
        this.ext_price_title_suffix = '(1M-5M)'
        this.ext_price_unit = '/Mbps/天'
        this.extProductColumns = [
          {
            field: 'sku',
            title: '线路类型',
            align: 'center',
            width: 130,
          },
          {
            field: 'network',
            title: '网络类型',
            align: 'center',
            width: 130,
          },
        ]
        this.extPriceColumns = [
          {
            field: 'bandwidth_above_5m_price',
            title: '芒果云价格(>5M)',
            align: 'center',
            width: 180,
            slots: {
              default: ({ row }) => {
                return [
                  <a-input-number
                    v-model={row.bandwidth_above_5m_price}
                    precision={4}
                    min={0}
                  />,
                  <span>/Mbps/天</span>,
                ]
              },
            },
          },
        ]
      }
      this.list.fetchData()
      this.extList.fetchData()
    },
    handleSlbPostpaidTypeChange (val) {
      console.log(val)
      if (val === 'lcu') {
        this.table_title_suffix = '-流量'
        this.price_title_suffix = '(流量)'
        this.price_unit = '/GB'
        this.productColumns = []
        this.priceColumns = []
        // 第二张表
        this.showExtTable = false
      } else {
        this.showExtTable = true
        this.table_title_suffix = '-规格费'
        this.price_title_suffix = '(规格)'
        this.price_unit = '/小时'
        this.productColumns = [
          {
            field: 'spec_name',
            title: '规格',
            align: 'center',
            width: 130,
          },
        ]
        this.priceColumns = []
        this.ext_table_title_suffix = '-网络费'
        this.ext_price_title_suffix = '(流量)'
        this.ext_price_unit = '/GB'
        this.extProductColumns = []
        this.extPriceColumns = [
          {
            field: 'bandwidth_price',
            title: '芒果云价格(1M-5M)',
            align: 'center',
            width: 180,
            slots: {
              default: ({ row }) => {
                return [
                  <a-input-number
                    v-model={row.bandwidth_price}
                    precision={4}
                    min={0}
                  />,
                  <span>/小时/M</span>,
                ]
              },
            },
          },
          {
            field: 'bandwidth_gt_5_price',
            title: '芒果云价格(>5M)',
            align: 'center',
            width: 180,
            slots: {
              default: ({ row }) => {
                return [
                  <a-input-number
                    v-model={row.bandwidth_gt_5_price}
                    precision={4}
                    min={0}
                  />,
                  <span>/小时/M</span>,
                ]
              },
            },
          },
        ]
      }
      this.getBasePriceList()
      this.list.fetchData()
      this.extList.fetchData()
    },
    getParam () {
      const ret = {
        // ...this.getParams,
        details: true,
        // user_id: this.userInfo.id,
        provider: this.params.row.provider,
        product_price_id: this.params.row.id,
        billing_type: this.params.row.charge_type,
      }
      const product_code = this.params.row.product_code
      switch (product_code) {
        case 'eip':
          Object.assign(ret, {
            charge_type: this.eipPostpaidType,
          })
          break
        case 'slb':
          Object.assign(ret, {
            charge_type: this.slbPostpaidType,
          })
          break
        default:
          break
      }
      return ret
    },
    async save (data, flag) {
      try {
        let url = ''
        const product_code = this.params.row.product_code
        switch (product_code) {
          case 'ecs':
            url = '/v3/resource_bill_ecs_prices/upset'
            break
          case 'disk':
            url = '/v3/resource_bill_ebs_prices/upset'
            break
          case 'eip':
            if (!flag) {
              url = '/v3/resource_bill_eip_prices/instance-upset'
            } else {
              url = '/v3/resource_bill_eip_prices/network-upset'
            }
            break
          case 'slb':
            if (!flag) {
              url = '/v2/resource_bill_slb_lcu_prices/save-slb-price'
            } else {
              url = '/v2/resource_bill_slb_spec_prices/upset-network'
            }
            break
          case 'nat_gateway':
            url = '/v3/resource_bill_nat_gateway_prices/upset'
            break
          case 'snapshot':
            url = '/v3/resource_bill_snapshot_prices/upset'
            break

          default:
            break
        }
        return this.$http.post(url, data)
      } catch (error) {
        throw error
      }
    },
    async handleConfirm () {
      // if (this.list.selectedItems.length === 0) {
      //   this.$message.error('请至少选择一项！')
      //   return
      // }
      // 第一张表全部数据
      const firstList = []
      // 第二张表全部数据
      const secondExtList = []
      Object.values(this.list.data).forEach(item => {
        firstList.push(item.data)
      })
      if (this.eipPostpaidType === 'bandwidth' && HYPERVISORS_MAP.mgecloud.key === this.params.row.provider.toLowerCase()) {
        // 移动云-带宽 可以为0
      } else {
        if (firstList.some(item => !item.price)) {
          this.$message.error('请先进行定价！')
          return
        }
      }
      if (this.showExtTable) {
        // if (this.extList.selectedItems.length === 0) {
        //   this.$message.error('请至少选择一项！')
        //   return
        // }
        Object.values(this.extList.data).forEach(item => {
          secondExtList.push(item.data)
        })
        if (this.extList.selectedItems.some(item => !item.price)) {
          this.$message.error('请先进行定价！')
          return
        }
      }
      // console.log('this.list.selectedItems=', this.list.selectedItems)
      this.loading = true
      try {
        const promiseArr = []
        // slb第一张表
        if (this.showExtField) {
          promiseArr.push(this.setBasePriceList())
        }
        const data = firstList.map(item => {
          return {
            ...item,
            price: item.price,
            product_price_id: this.params.row.id || '',
            provider: this.params.row.provider,
            billing_type: this.params.row.charge_type,
          }
        })
        if (this.params.row.product_code === 'slb') {
          const dataLsit = {
            charge_type: this.slbPostpaidType,
            data,
          }
          promiseArr.push(this.save(dataLsit))
        } else {
          if (!this.isEipPrepaid) {
            promiseArr.push(this.save(data))
          }
        }
        // 更新第二张表
        if (this.showExtTable) {
          const extData = secondExtList.map(item => {
            return {
              ...item,
              price: item.price,
              product_price_id: this.params.row.id || '',
            }
          })
          promiseArr.push(this.save(extData, true))
        }
        await Promise.all(promiseArr)
        this.$message.success('定价设置成功')
        this.cancelDialog()
        this.params.refresh()
      } finally {
        this.loading = false
      }
    },

  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0;
}
.charge-type{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
