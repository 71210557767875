<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">复制二次定价数据</div>
    <div slot="body">
      <div>
        <p>正在复制定价，已处理{{ stateSuccess }}/{{ stateTotal }}</p>
      </div>
      <a-table
        :columns="columns"
        :dataSource="tableData"
        :pagination="false"
        :loading="loading">
        <template slot="state" slot-scope="text, record">
          {{ stateMap[record.state] }}
        </template>
      </a-table>
    </div>
    <div slot="footer">
      <div class="">
        <a-button type="primary" @click="cancelDialog">确认</a-button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import http from '@/utils/http'

export default {
  name: 'CopyPricingListForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      tableData: [],
      columns: [
        {
          title: '租户',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '状态',
          dataIndex: 'state',
          key: 'state',
          scopedSlots: { customRender: 'state' },
        },
      ],
      stateMap: {
        failed: '复制失败',
        success: '复制完成',
        waiting: '复制中',
      },
      stateTotal: 0,
      stateSuccess: 0,
    }
  },
  async created () {
    this.getProgressLsit()
  },
  methods: {
    async getProgressLsit () {
      // 内部函数用于执行实际的数据请求和处理
      const fetchData = async () => {
        try {
          const params = {
            user_id: this.params.userId,
          }
          const result = await http.get('/v3/user_second_prices/copy-second-price', { params })
          if (+result.status !== 200) return

          this.tableData = result.data.data
          this.stateTotal = this.tableData.length
          this.stateSuccess = this.tableData.filter(item => item.state === 'success').length

          // 检查是否有任何项的状态是 'waiting'
          const hasWaitingState = this.tableData.some(item => item.state === 'waiting')

          // 如果存在 'waiting' 状态的项，继续轮询
          if (hasWaitingState) {
            setTimeout(fetchData, 1000) // 500ms 后再次调用 fetchData
          }
        } catch (error) {
          console.error('获取数据失败:', error)
        }
      }

      fetchData() // 启动轮询
    },
  },
}
</script>
<style lang="scss" scoped></style>
