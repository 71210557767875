<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">{{$t('compute.disk_perform_create_snapshot')}}</div>
    <div slot="body">
      <a-alert :message="errorInfo" banner v-if="errorInfo !== ''" />
      <dialog-selected-tips :count="params.data.length" :action="$t('compute.disk_perform_create_snapshot')" :name="$t('dictionary.disk')" />
      <dialog-table :data="params.data" :columns="params.columns.slice(0, 3)" />
      <a-form
        :form="form.fc">
        <a-form-item :label="$t('compute.text_415')" v-bind="formItemLayout">
          <a-input v-decorator="decorators.name" :placeholder="$t('validator.snapshotName')" />
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="text-truncate">{{$t('compute.text_286')}}</div>
          <div class="ml-2 prices">
            <div v-if="price" class="d-flex" style="color: #f5222d;font-size: 24px;">
              <div>{{ price }}</div>
            </div>
            <div v-else class="text-truncate" style="color: #999; font-size: 12px;">
              <span v-html="priceTips" />
            </div>
          </div>
        </div>
        <div>
          <a-button type="primary" @click="handleConfirm" :loading="loading" :disabled="confirmDisabled">{{ $t('dialog.ok') }}</a-button>
          <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
        </div>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import { HYPERVISORS_MAP } from '@/constants'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { numerify } from '@/filters'

export default {
  name: 'DiskCreateSnapshotDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
      },
      decorators: {
        name: [
          'name',
          {
            validateFirst: true,
            rules: [
              { required: true, message: this.$t('compute.text_416') },
              { validator: this.$validate('snapshotName') },
            ],
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 21,
        },
        labelCol: {
          span: 3,
        },
      },
      serversOpts: [],
      currency: '¥',
      price: null,
      priceFormat: null,
      priceTips: '--',
      curPrice: null,
    }
  },
  computed: {
    errorInfo () {
      if (this.isKvm) {
        if (this.isCeph) return ''
        if (this.manualSnapshotCount === this.maxManualSnapshotCount) {
          return this.$t('compute.text_417')
        }
        return this.$t('compute.text_418', [this.manualSnapshotCount, this.maxManualSnapshotCount - this.manualSnapshotCount])
      }
      return ''
    },
    confirmDisabled () {
      if (!this.isKvm) return
      if (this.isCeph) return
      return this.manualSnapshotCount === this.maxManualSnapshotCount
    },
    isKvm () {
      return this.params.data[0].provider === HYPERVISORS_MAP.kvm.provider
    },
    isHuawei () {
      return this.params.data[0].provider === HYPERVISORS_MAP.huawei.provider
    },
    isCeph () {
      return this.params.data[0].storage_type === 'rbd'
    },
    maxManualSnapshotCount () {
      return this.params.data[0].max_manual_snapshot_count || 2
    },
    manualSnapshotCount () {
      return this.params.data[0].manual_snapshot_count || 0
    },
  },
  created () {
    const params = {
      details: false,
      disk: this.params.data[0].id,
    }
    new this.$Manager('servers').list({ params })
      .then((res) => {
        this.serversOpts = res.data.data
      })
    this.getPrice()
  },
  methods: {
    priceFormat1 (price, unit) {
      const str = price.toString()
      const ary = str.split('.')
      let len = 0
      if (ary.length > 1) {
        len = ary[1].length
      }
      let u = '0,0.00'
      if (len > 2) {
        for (let i = 0; i < len - 2; i++) {
          u += '0'
        }
      }
      price = numerify(price, u)

      return `${this.currency} ${price}${unit}`
    },
    async getPrice () {
      await this.$nextTick()
      const params =
        [{
          provider: this.params.data[0].provider,
          area_id: this.params.data[0].area_mapping_id,
          // billing_type: this.params.data[0].billing_type,
          billing_type: 'postpaid',
          specs: this.params.data[0].disk_type === 'sys' ? 'system' : 'data',
          size: this.params.data[0].disk_size,
        }]

      this.$http.post('/v2/snapshots/price', params).then((res) => {
        // console.log('res.data=', res.data)
        if (res.data && res.data[0]) {
          const price = res.data
          // this.$emit('curPrice', price)
          this.curPrice = price
          this.price = this.priceFormat1(res.data[0].price, '/时')
        }
      })
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        this.form.fc.validateFields((err, values) => {
          if (!err) {
            resolve(values)
          } else {
            reject(err)
          }
        })
      })
    },
    doCreate (data) {
      return new this.$Manager('snapshots').create({ data })
    },
    async handleConfirm () {
      this.loading = true
      try {
        let values = await this.validateForm()
        values = {
          ...values,
          disk: this.params.data[0].id,
        }
        values.cur_price = this.curPrice
        this.loading = true
        await this.doCreate(values)
        this.loading = false
        this.params.refresh()
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
