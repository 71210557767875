<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">{{$t('compute.disk_perform_create_snapshot')}}</div>
    <div slot="body">
      <a-alert class="mb-2" type="warning">
        <!-- <div slot="message">{{$t('compute.text_1251')}}</div> -->
        <div slot="message">1. 状态异常的硬盘不支持创建快照. 2. {{privateEnvText}}仅支持本地硬盘创建快照（以镜像为系统盘的硬盘不支持创建快照）</div>
      </a-alert>
      <dialog-selected-tips :name="$t('dictionary.server')" :count="params.data.length" :action="$t('compute.disk_perform_create_snapshot')" />
      <dialog-table
        :data="params.data"
        :columns="params.columns.slice(0, 3)" />
      <a-form :form="form.fc" v-bind="formItemLayout">
        <a-form-item :label="$t('compute.text_1071')" v-if="isKvm || isEsxi">
          <a-radio-group
            v-decorator="decorators.snapshotType"
            @change="snapshotTypeChangeHandle">
            <a-tooltip>
              <template slot="title" v-if="isEsxi">
                <span>{{$t('compute.text_1358')}}</span>
              </template>
              <a-radio value="disk" :disabled="isEsxi">{{$t('compute.text_101')}}</a-radio>
            </a-tooltip>
            <a-radio value="instance">{{$t('compute.text_102')}}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          :label="$t('compute.text_1254')"
          v-if="isDiskSnapshot">
          <base-select
            style="width: 100%"
            v-decorator="decorators.disk"
            :params="diskParams"
            :select-props="{ placeholder: $t('compute.text_1085') }"
            resource="disks"
            idKey="disk_id"
            nameKey="disk"
            :label-format="getDiskLabel"
            :ctx="[['servers', this.params.data[0].id]]"
            :filterable="true"
            :isDefaultSelect="true"
            :item.sync="selectDisk"
            :mapper="listMapper" />
        </a-form-item>
        <a-form-item
          :label="$t('compute.text_415')">
          <a-input
            v-decorator="decorators.snapshotName"
            :placeholder="$t('validator.snapshotName')" />
          <div slot="extra">
            <div v-if="showRepeatTips">{{$t('compute.text_1091')}}</div>
            <div v-show="!isDiskSnapshot">{{$t('compute.text_1255', [ diskCount ])}}</div>
          </div>
        </a-form-item>
        <a-form-item v-if="!isDiskSnapshot">
          <a-tooltip>
            <template v-if="!isRunningVm" slot="title">
              {{$t('compute.create_mem_snapshot_limit')}}
            </template>
            <a-checkbox
              v-decorator="decorators.with_memory"
              :disabled="!isRunningVm">
              {{$t('compute.create_mem_snapshot_same_time')}}
            </a-checkbox>
          </a-tooltip>
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="text-truncate">{{$t('compute.text_286')}}</div>
          <div class="ml-2 prices">
            <div v-if="price" class="d-flex" style="color: #f5222d;font-size: 24px;">
              <div>{{ price }}</div>
            </div>
            <div v-else class="text-truncate" style="color: #999; font-size: 12px;">
              <span v-html="priceTips" />
            </div>
          </div>
        </div>
        <div>
          <a-button type="primary" @click="handleConfirm" :loading="loading" :disabled="disabledSubmit">{{$t("dialog.ok")}}</a-button>
          <a-button @click="cancelDialog">{{ $t("dialog.cancel") }}</a-button>
        </div>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import * as R from 'ramda'
import { DISK_TYPE } from '@Compute/constants'
import { INPUT_DEBOUNCE_TIMER } from '@/constants/config'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { sizestr } from '@/utils/utils'
import { typeClouds } from '@/utils/common/hypervisor'
import { numerify } from '@/filters'

const hypervisorMap = typeClouds.hypervisorMap

export default {
  name: 'VmSnapshotCreateDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      showRepeatTips: false,
      form: {
        fc: this.$form.createForm(this, {
          name: 'snapshort_create_form',
          onFieldsChange: this.onFieldsChange,
          onValuesChange: this.onValuesChange,
        }),
        fd: {
          snapshotName: '',
          snapshotType: this.params.data[0].hypervisor === hypervisorMap.esxi.key ? 'instance' : 'disk',
        },
      },
      decorators: {
        snapshotType: [
          'snapshotType',
          {
            initialValue: this.params.data[0].hypervisor === hypervisorMap.esxi.key ? 'instance' : 'disk',
          },
        ],
        disk: [
          'disk',
          {
            rules: [
              { required: true, message: this.$t('compute.text_1085'), trigger: 'change' },
            ],
          },
        ],
        snapshotName: [
          'snapshotName',
          {
            validateFirst: true,
            rules: [
              { required: true, message: this.$t('compute.text_1256') },
              { validator: this.$validate('snapshotName') },
            ],
          },
        ],
        with_memory: [
          'with_memory',
        ],
      },
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      selectDisk: {},
      disabledSubmit: false,
      currency: '¥',
      price: null,
      priceFormat: null,
      priceTips: '--',
      curPrice: null,
    }
  },
  computed: {
    privateEnvText () {
      if (this.$store.getters.userInfo.user_type === 'customer') {
        return ''
      } else {
        return this.$t('dictionary.private_env')
      }
    },
    isKvm () {
      return this.params.data[0].hypervisor === hypervisorMap.kvm.key
    },
    isEsxi () {
      return this.params.data[0].hypervisor === hypervisorMap.esxi.key
    },
    diskParams () {
      return {
        scope: this.$store.getters.scope,
        details: true,
        with_meta: true,
      }
    },
    isDiskSnapshot () {
      return this.form.fd.snapshotType === 'disk'
    },
    manager () {
      return new this.$Manager(this.isDiskSnapshot ? 'snapshots' : 'instance_snapshots', 'v2')
    },
    diskCount () {
      return this.params.data[0].disk_count
    },
    isRunningVm () {
      return this.params.data[0].status === 'running'
    },
  },
  watch: {
    'form.fd.snapshotName' (val) {
      this.debounceCheckTemplateName()
    },
    'selectDisk' (val) {
      this.disabledSubmit = val.storage_type === 'nova'
      // 计算价格
      this.getPrice(val.disk_type, val.disk_size)
    },
  },
  created () {
    this.debounceCheckTemplateName = debounce(() => {
      this.checkTemplateName()
    }, INPUT_DEBOUNCE_TIMER)
  },
  methods: {
    priceFormat1 (price, unit) {
      const str = price.toString()
      const ary = str.split('.')
      let len = 0
      if (ary.length > 1) {
        len = ary[1].length
      }
      let u = '0,0.00'
      if (len > 2) {
        for (let i = 0; i < len - 2; i++) {
          u += '0'
        }
      }
      price = numerify(price, u)

      return `${this.currency} ${price}${unit}`
    },
    async getPrice (disk_type, disk_size) {
      await this.$nextTick()
      const params =
        [{
          provider: this.params.data[0].provider,
          area_id: this.params.data[0].area_mapping_id,
          // billing_type: this.params.data[0].billing_type,
          billing_type: 'postpaid',
          specs: disk_type === 'sys' ? 'system' : 'data',
          size: disk_size,
        }]

      this.$http.post('/v2/snapshots/price', params).then((res) => {
        // console.log('res.data=', res.data)
        if (res.data && res.data[0]) {
          const price = res.data
          // this.$emit('curPrice', price)
          this.curPrice = price
          this.price = this.priceFormat1(res.data[0].price, '/时')
        }
      })
    },
    listMapper (list) {
      // 是否需要根据不可用的类型过滤数据
      if (this.params.specs) {
        const filterTypes = []
        this.params.specs.forEach(item => {
          if (Object.values(item).includes(true)) {
            const type = Object.keys(item).toString() === 'system' ? 'sys' : 'data'
            filterTypes.push(type)
          }
        })

        if (filterTypes.length > 0) {
          list = list.filter(item => !filterTypes.includes(item.disk_type))
        }
      }

      // 移动云只能处理数据盘
      if (this.params.data[0].hypervisor === hypervisorMap.mgecloud.key) {
        return list.filter(item => item.disk_type === 'data')
      }
      return list
    },
    checkTemplateName () {
      const snapshotName = this.form.fd.snapshotName
      if (!R.isNil(snapshotName) && !R.isEmpty(snapshotName)) {
        this.manager.get({ id: snapshotName, params: { scope: this.$store.getters.scope } })
          .then(res => {
            const data = res.data
            if (!R.isNil(data) && !R.isEmpty(data)) {
              this.showRepeatTips = true // 重复名字
            }
          }).catch(() => {
            this.showRepeatTips = false
          })
      } else {
        this.showRepeatTips = false
      }
    },
    validateForm (fileds = []) {
      return new Promise((resolve, reject) => {
        this.form.fc.validateFields(fileds, (err, values) => {
          if (!err) {
            resolve(values)
          } else {
            reject(err)
          }
        })
      })
    },
    async doCreateDiskSnapshot () {
      const values = await this.validateForm(['disk', 'snapshotName'])
      const params = {
        disk: values.disk,
        generate_name: values.snapshotName,
      }
      params.cur_price = this.curPrice
      return this.manager.create({ data: params })
    },
    async doCreateInstanceSnapshot () {
      const values = await this.validateForm(['snapshotName', 'with_memory'])
      const params = {
        generate_name: values.snapshotName,
      }
      if (values.with_memory) {
        params.with_memory = true
      }
      return this.params.onManager('performAction', {
        id: this.params.data[0].id,
        steadyStatus: ['running', 'ready'],
        managerArgs: {
          action: 'instance-snapshot',
          data: params,
        },
      })
    },
    async handleConfirm () {
      this.loading = true
      try {
        if (this.isDiskSnapshot) {
          await this.doCreateDiskSnapshot()
        } else {
          await this.doCreateInstanceSnapshot()
        }
        this.loading = false
        this.params.refresh()
        this.cancelDialog()
        this.$message.success(this.$t('compute.text_322'))
      } catch (error) {
        this.loading = false
        // this.cancelDialog()
      }
    },
    getDiskLabel (item) {
      return `${item.disk}（${
        item.disk_type === DISK_TYPE.sys.value
          ? DISK_TYPE.sys.text
          : DISK_TYPE.data.text
      }, ${sizestr(item.disk_size, 'M', 1024)}）`
    },
    snapshotTypeChangeHandle (e) {
      this.$nextTick(() => {
        this.debounceCheckTemplateName()
      })
    },
    onValuesChange (props, values) {
      Object.keys(values).forEach((key) => {
        this.form.fd[key] = values[key]
      })
    },
  },
}
</script>
