import {
  // getProjectTableColumn,
  // getRegionTableColumn,
  getEnabledTableColumn,
  // getBrandTableColumn,
  // getCopyWithContentTableColumn,
  getNameDescriptionTableColumn,
  // getBillingTypeTableColumn,
  // getOsArch,
  // getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import SystemIcon from '@/sections/SystemIcon'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'
// import { PRODUCT_TYPES } from '../../../constant'

export default {
  created () {
    this.columns = [
      {
        field: 'code',
        title: '产品编码',
      },
      getNameDescriptionTableColumn({
        onManager: this.onManager,
        hideField: true,
        edit: false,
        editDesc: false,
        showDesc: false,
        // addLock: true,
        // addBackup: true,
        // formRules: [
        //   { required: true, message: i18n.t('compute.text_210') },
        //   { validator: this.$validate('resourceCreateName') },
        // ],
        slotCallback: row => {
          return (
            <side-page-trigger onTrigger={ () => this.handleOpenSidepage(row) }>{ row.name }</side-page-trigger>
          )
        },
      }),
      {
        field: 'product_type',
        title: '类型',
        slots: {
          default: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PRODUCT_TYPE).find(item => item.value === row.product_type)
            return [<span>{item?.label || '-'}</span>]
          },
        },
      },
      {
        field: 'provider',
        title: '厂商',
        slots: {
          default: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PROVIDER).find(item => item.value === row.provider)
            return [<span>{item?.label || '-'}</span>]
          },
        },
      },
      // getBrandTableColumn({
      //   title: '厂商',
      //   field: 'provider',
      // }),
      getEnabledTableColumn(),
    ]
  },
}
