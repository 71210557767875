<template>
    <div class="product-superiority">
        <div class="title">{{data.title}}</div>
        <div class="intro" v-if="data.intro">{{data.intro}}</div>
        <!-- <div class="h20" v-else /> -->
        <div class="list" :class="{'list-6' : data.list.length % 3 === 0 }">
            <div class="item" v-for="(item,index) in data.list" :key="index">
                <img class="img" :src="item.icon" alt="" />
                <div class="content">
                  <div class="name">{{item.name}}</div>
                  <div class="desc">{{item.desc}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import paramsMixin from '@/mixins/paramsMixin'
export default {
  name: 'ProductSuperiority',
  mixins: [paramsMixin],
  data () {
    return {
      list: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.product-superiority{
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;
  background: #FFF;

  .title{
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
    text-align: center;
  }

  .intro{
    height: 1.146vw;
    margin-top: 1.25vw;
    color: #646566;
    font-weight: normal;
    font-size: 0.729vw;
    line-height: 1.146vw;
    text-align: center;
  }

  .h20{
    height: 1.042vw;
  }

  .list{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 0.833vw;
    margin-top: 1.875vw;

    &.list-6{
      grid-template-columns: repeat(3,1fr);
    }

    .item{
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      min-width: 15vw;
      // height: 9.74vw;
      padding: 1.458vw 1.042vw 1.875vw;
      background: #FFF;
      border: 1px solid #E5E5E5;
      border-radius: 0.208vw;

      .img{
        width: 2.5vw;
        height: 2.5vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.25vw;

        .name{
          height: 1.146vw;
          color: #222222;
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 1.146vw;
        }

        .desc{
          margin-top: 0.833vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
          text-align: center;
        }
      }
    }
  }
}
</style>
