<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">同步目录价</div>
    <div slot="body">
      <p>同步目录价，会将该租户下的二次定价数据完全覆盖，无法找回，请谨慎操作！</p>
    </div>
    <div slot="footer">
      <div class="d-flex justify-content-center">
        <a-button type="primary" @click="handleConfirm" :loading="loading">提交同步</a-button>
        <a-button @click="cancelDialog">关闭页面</a-button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'UserSecondPriceSyncForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
    }
  },
  async created () {
    console.log('this.params=', this.params)
  },
  methods: {
    async handleConfirm () {
      // console.log('this.params=', this.params)
      this.loading = true
      try {
        const data = { user_id: this.params.userId }
        await this.$http.post('/v3/user_second_prices/sync-second-price', data)
        this.cancelDialog()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
