<template>
  <div class="second-price-info">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="text-truncate">启用二次定价</div>
        <div class="ml-2 switch-wrapper" @click.stop="handleSecondPriceEnabledClick">
          <div class="switch-click-area" />
          <a-switch class="switch-btn" v-model="secondPriceEnabled" />
        </div>
      </div>
      <div>
        <a-button type="link" icon="sync" @click="handleSync">同步目录价</a-button>
        <a-button type="link" icon="copy" @click="handleCopy">复制定价</a-button>
      </div>
    </div>
    <div v-if="secondPriceEnabled">
      <pricing-search
          @providerChange="handleProviderChange"
          @productTypeChange="handleProductTypeChange"
          @nameChange="handleNameChange"
          @chargeTypeChange="handleChargeTypeChange" />
      <vxe-grid
        :data="priceData"
        :columns="priceColumns" />
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import WindowsMixin from '@/mixins/windows'
import PricingSearch from './PricingSearch'
export default {
  name: 'SecondPriceInfo',
  components: {
    PricingSearch,
  },
  mixins: [WindowsMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      secondPriceEnabled: false,
      details: {
        effect_at: undefined,
      },
      priceData: [],
      priceColumns: [
        {
          title: '名称',
          field: 'product_name',
        },
        {
          title: '类型',
          field: 'product_type_name',
        },
        {
          title: '厂商',
          field: 'provider_name',
        },
        {
          title: '状态',
          field: 'enabled',
          slots: {
            default: ({ row }) => {
              return [<span>{ row.enabled ? '上架' : '下架' } </span>]
            },
          },
        },
        {
          title: '计费方式',
          field: 'charge_type',
          slots: {
            default: ({ row }) => {
              const item = this.getDictDatas(this.DICT_TYPE.CHARGE_TYPE).find(item => item.value === row.charge_type)
              return [<span>{item?.label || '-'}</span>]
            },
          },
        },
        {
          title: '操作',
          field: 'actions',
          slots: {
            default: ({ row }) => {
              return [
                // <a-button type="link" onClick={() => this.handleView(row)}>查看</a-button>,
                <a-button type="link" onClick={() => this.handleSinglePricing(row)}>定价设置</a-button>,
              ]
            },
          },
        },
      ],
      tableParams: {},
    }
  },
  watch: {
    secondPriceEnabled: {
      handler (newValue, oldValue) {
        if (newValue) {
          this.load()
        }
      },
      immediate: true,
    },
  },
  created () {
    this.getEnabledStatus()
  },
  methods: {
    async getEnabledStatus () {
      const res = await this.$http.get('/v3/user_second_prices/user-enabled', {
        params: { user_id: this.data.id },
      })
      this.secondPriceEnabled = !!res.data.enabled
    },
    handleSecondPriceEnabledClick () {
      // this.$message.info('点击启用二次定价')
      const oldVal = Object.freeze(this.secondPriceEnabled)
      const text = this.secondPriceEnabled ? '禁用' : '启用'
      const that = this
      this.$confirm({
        title: `确认要${text}二次定价`,
        async onOk () {
          const data = { user_id: that.data.id, enabled: !oldVal }
          try {
            const res = await that.$http.post('/v3/user_second_prices/user-state', data)
            console.log(res)

            that.secondPriceEnabled = !oldVal
          } catch (error) {
            console.log(error)
          }
        },
        onCancel () {
          that.secondPriceEnabled = oldVal
        },
      })
    },
    handleSync () {
      // this.$message.info('同步目录价')
      this.createDialog('UserSecondPriceSyncForCustomerDialog', {
        userId: this.data.id,
      })
    },
    handleCopy () {
      this.createDialog('CopyPricingForCustomerDialog', {
        userName: this.data.name,
        userId: this.data.id,
      })
    },
    load () {
      // this.$http.get('/v1/product_users', {
      //   params: { user_id: this.data.id },
      // })
      //   .then((response) => {
      //     const res = response.data
      //     // console.log('res=', res)
      //     const data = res.data
      //     if (data && data.length > 0) {
      //       this.details = data[0]
      //       // 加载产品定价,产品ID
      //       this.loadProductPrice()
      //     } else {
      //       this.details = {
      //         effect_at: undefined,
      //       }
      //     }
      //   })
      this.loadProductPrice()
    },
    loadProductPrice () {
      const defaultParams = {
        enabled: true,
        // product_user_id: this.details.id,
      }

      // if (!this.tableParams.product_id) {
      //   const product_ids = _.flatten(this.details.providers_products.map(item => item.product_ids))
      //   if (product_ids && product_ids.length > 0) {
      //     Object.assign(defaultParams, {
      //       product_id: product_ids,
      //     })
      //   }
      // }

      // if (!this.tableParams.provider) {
      //   const providers = this.details.providers
      //   if (providers && providers.length > 0) {
      //     Object.assign(defaultParams, {
      //       provider: providers,
      //     })
      //   }
      // }
      this.$http.get('/v1/product_prices', {
        params: {
          // user_id: this.data.id,
          ...this.tableParams,
          ...defaultParams,
        },
      })
        .then((response) => {
          const res = response.data
          // console.log('res=', res)
          const data = res.data
          if (data && data.length > 0) {
            this.priceData = data
          } else {
            this.priceData = []
          }
        })
    },
    handleView (row) {
      // TODO 查看
      console.log(row)
      this.createDialog('SinglePricingUpdateForCustomerDialog', {
        user: this.data,
        row: row,
        mode: 'view',
      })
    },
    handleSinglePricing (row) {
      // TODO 单独定价
      console.log(row)
      let basePriceResource = '' // 默认配置，获取基础价格,按用量，按规格
      let resource = '' // 默认配置，按量付费时，按流量
      let extResource = '' // 表格2
      let unSupported = false // 是否不支持
      let unSupportedMsg = ''
      const product_code = row.product_code
      const billing_type = row.billing_type || row.charge_type
      switch (product_code) {
        case 'ecs':
          resource = 'resource_ecs_second_prices/ecs-second-price'
          break
        case 'ebs':
        case 'disk':
          resource = 'resource_ebs_second_prices/ebs-second-prices'
          break
        case 'eip':
          resource = 'resource_eip_second_prices/eip-instance-second-price'
          extResource = 'resource_eip_second_prices/eip-network-second-price'
          break
        case 'slb':
          basePriceResource = 'v3/resource_slb_lcu_second_prices/base-second-price'
          resource = 'resource_slb_lcu_second_prices/slb-second-price-list'
          extResource = 'resource_slb_spec_second_prices/slb-network-second-price'
          unSupported = billing_type === 'prepaid'
          unSupportedMsg = '负载均衡SLB暂不支持预付费'
          break
        case 'nat_gateway':
          resource = 'resource_nat_gateway_second_prices/nat-gateway-second-price'
          break
        case 'snapshot':
          resource = 'resource_snapshot_second_prices/snapshot-second-prices'
          unSupported = billing_type === 'prepaid'
          unSupportedMsg = '快照暂不支持预付费'
          break
        default:
          break
      }
      if (unSupported) {
        this.$message.warning(unSupportedMsg)
        return
      }
      // 定价设置
      this.createDialog('ProductSecondPricingUpdateDialog', {
        refresh: this.load,
        row: row,
        userId: this.data.id,
        basePriceResource: basePriceResource,
        resource: resource,
        extResource: extResource,
      })
      // this.createDialog('SinglePricingUpdateForCustomerDialog', {
      //   user: this.data,
      //   mode: 'edit',
      //   row: row,
      // })
    },
    handleProviderChange (val) {
      console.log('handleProviderChange(val)', val)
      Object.assign(this.tableParams, { provider: val })
      this.loadProductPrice()
    },
    handleProductTypeChange (val) {
      console.log('handleProductTypeChange(val)', val)
      Object.assign(this.tableParams, { product_type: val })
      this.loadProductPrice()
    },
    handleNameChange (val) {
      console.log('handleNameChange(val)', val)
      Object.assign(this.tableParams, { product_id: val })
      this.loadProductPrice()
    },
    handleChargeTypeChange (val) {
      console.log('handleChargeTypeChange(val)', val)
      Object.assign(this.tableParams, { charge_type: val })
      this.loadProductPrice()
    },
  },
}
</script>

<style lang="scss" scoped>
.second-price-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  .switch-wrapper{
    position: relative;
    .switch-click-area{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 20;
    }
  }
}
</style>
