import {
  getNameDescriptionTableColumn,
  getStatusTableColumn,
  // getBrandTableColumn,
  getProjectTableColumn,
  getRegionTableColumn,
  getTagTableColumn,
  getTimeTableColumn,
  getBillingTypeTableColumn,
} from '@/utils/common/tableColumn'
import { sizestr } from '@/utils/utils'
import i18n from '@/locales'
import { getAssociateNameTableColumn, getIPWithBgpTypeTableColumn } from '../utils/columns'

export default {
  created () {
    this.columns = [
      getNameDescriptionTableColumn({
        onManager: this.onManager,
        hideField: true,
        slotCallback: row => {
          return (
            <side-page-trigger onTrigger={ () => this.handleOpenSidepage(row) }>{ row.name }</side-page-trigger>
          )
        },
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.name')
        },
      }),
      getStatusTableColumn({
        statusModule: 'eip',
        vm: this,
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.status')
        },
      }),
      getTagTableColumn({
        onManager: this.onManager,
        resource: 'eips',
        columns: () => this.columns,
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.metadata')
        },
      }),
      // {
      //   field: 'ip_addr',
      //   title: 'IP',
      //   width: 140,
      //   hidden: () => {
      //     return this.$isScopedPolicyMenuHidden('eip_hidden_columns.ip_addr')
      //   },
      // },
      getIPWithBgpTypeTableColumn({
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.ip_addr')
        },
      }),
      {
        field: 'bandwidth',
        title: i18n.t('network.text_195'),
        minWidth: 80,
        showOverflow: 'ellipsis',
        formatter: ({ row, cellValue }) => {
          if (!row.bandwidth) return '-'
          return sizestr(row.bandwidth, 'M', 1024)
        },
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.bandwidth')
        },
      },
      getBillingTypeTableColumn({
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.billing_type')
        },
      }),
      {
        field: 'charge_type',
        title: i18n.t('network.text_192_0'),
        minWidth: 80,
        formatter: ({ row }) => {
          const cellValue = row.charge_type
          if (cellValue === 'traffic') {
            return i18n.t('network.text_193')
          }
          if (cellValue === 'bandwidth') {
            return i18n.t('network.text_194')
          }
          return cellValue
        },
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.charge_type')
        },
      },
      getAssociateNameTableColumn({
        vm: this,
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.associate_name')
        },
      }),
      getProjectTableColumn({
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.tenant')
        },
      }),
      getRegionTableColumn({
        vm: this,
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.region')
        },
      }),
      getTimeTableColumn({
        hidden: () => {
          return this.$isScopedPolicyMenuHidden('eip_hidden_columns.created_at')
        },
      }),
    ]
  },
}
