<template>
    <div class="hot-product">
        <div class="link-title">{{title}}</div>
        <div class="link-box">
            <template v-if="hasChildren">
                <div class="link-list" v-for="(item,index) in menus" :key="index">
                    <div class="link-title">{{item.name}}</div>
                    <div class="link-content">
                        <div class="link-item-wrap" v-for="(c,i) in item.children" :key="i">
                            <a class="link-item" href="#" @click.prevent="handleProductClick(c)" @mouseenter="handleProductMouseEnter(c)">{{c.name}}</a>
                            <span class="is-hot" v-if="c.is_hot">HOT</span>
                            <span class="is-new" v-if="c.is_new">NEW</span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="link-list">
                    <div class="link-content">
                        <div class="link-item-wrap" v-for="(c,i) in menus" :key="i">
                            <a class="link-item" href="#" @click.prevent="handleProductClick(c)" @mouseenter="handleProductMouseEnter(c)">{{c.name}}</a>
                            <span class="is-hot" v-if="c.is_hot">HOT</span>
                            <span class="is-new" v-if="c.is_new">NEW</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ProductMenu',
  props: {
    title: {
      type: String,
      default: '',
    },
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {

    }
  },
  computed: {
    hasChildren () {
      return this.menus.some(item => item.children && item.children.length)
    },
  },
  methods: {
    handleProductClick (item) {
      if (item.developing !== 1) {
        this.$emit('menu-click', item)
      }
    },
    handleProductMouseEnter (item) {
      if (item.developing === 1) {
        this.$message.info('服务建设中')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hot-product {
    height: 100%;
    padding: 2.083vw;

    .link-title{
        color: #3D3D3D;
        font-weight: 500;
        font-size: 1.042vw;
        line-height: 1.563vw;
    }

    .link-box{
        display: flex;
        height: 100%;
        margin-top: 1.042vw;

        .link-list{
            display: flex;
            flex-direction: column;
            width: 9.375vw;
            height: 100%;

            .link-title{
                height: 1.146vw;
                margin-bottom: 0.625vw;
                color: #999;
                font-size: 0.729vw;
                line-height: 1.146vw;
            }

            .link-content{
                display: flex;
                flex-direction: column;

                .link-item-wrap{
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.625vw;

                    .link-item{
                        color: #222;
                        font-size: 0.729vw;
                        line-height: 1.146vw;
                        white-space: nowrap;
                        cursor: pointer;

                        &:hover{
                            text-decoration: underline;
                            text-underline-offset: 0.104vw;
                        }

                    }

                    .is-hot{
                        margin-left: 0.417vw;
                        color: #F33E3E;
                        font-weight: normal;
                        font-size: 0.625vw;
                        line-height: 0.938vw;
                    }

                    .is-new{
                        margin-left: 0.417vw;
                        color: #10A300;
                        font-weight: normal;
                        font-size: 0.625vw;
                        line-height: 0.938vw;
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }
                }

            }
        }
    }
}
</style>
