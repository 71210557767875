<template>
  <div class="pricing-search">
    <a-form :form="form.fc" layout="inline" v-bind="formLayout">
    <a-row type="flex">
      <a-col :span="6" style="display: flex;">
      <a-form-item label="厂商" class="search-item">
        <a-select placeholder="请选择厂商" v-model="provider"  class="search-item" @change="handleProviderChange" allowClear>
            <a-select-option v-for="item of this.getDictDatas(DICT_TYPE.PROVIDER)" :key="item.value" :value="item.value">
              {{item.label}}
            </a-select-option>
        </a-select>
      </a-form-item>
      </a-col>
      <a-col :span="6" style="display: flex;">
      <a-form-item label="类型" class="search-item">
        <a-select placeholder="请选择资源类型" v-model="product_type" class="search-item" @change="handleProductTypeChange" allowClear>
            <a-select-option v-for="item of this.getDictDatas(DICT_TYPE.PRODUCT_TYPE)" :key="item.value" :value="item.value">
              {{item.label}}
            </a-select-option>
        </a-select>
      </a-form-item>
      </a-col>
      <a-col :span="6" style="display: flex;">
      <a-form-item label="资源" class="search-item">
        <a-select placeholder="请选择资源" v-model="product_id" class="search-item" @change="handleNameChange" allowClear>
            <a-select-option v-for="item of productOpts" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
        </a-select>
      </a-form-item>
      </a-col>
      <a-col :span="6" style="display: flex;">
      <a-form-item label="计费方式" class="search-item">
        <a-select placeholder="请选择计费方式" v-model="charge_type" class="search-item" @change="handleChargeTypeChange" allowClear>
            <a-select-option v-for="item of this.getDictDatas(DICT_TYPE.CHARGE_TYPE)" :key="item.value" :value="item.value">
              {{item.label}}
            </a-select-option>
        </a-select>
      </a-form-item>
      </a-col>
    </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'PricingSearch',
  data () {
    return {
      form: {
        fc: this.$form.createForm(this),
      },
      formLayout: {
        wrapperCol: {
          md: { span: 16 },
          xl: { span: 18 },
          xxl: { span: 20 },
        },
        labelCol: {
          md: { span: 8 },
          xl: { span: 6 },
          xxl: { span: 4 },
        },
      },
      decorators: {
        provider: ['provider'],
        product_type: ['product_type'],
        name: ['name'],
        charge_type: ['charge_type'],
      },
      provider: undefined,
      product_type: undefined,
      product_id: undefined,
      charge_type: undefined,
      productOpts: [],
    }
  },
  computed: {
    providerParams () {
      return {
        usable: true,
        public_cloud: true,
        // ...this.scopeParams,
      }
    },
  },
  methods: {
    async fetchProducts (product_type) {
      const response = await this.$http({
        method: 'GET',
        url: '/v1/products',
        params: {
          scope: 'system',
          show_fail_reason: true,
          details: false,
          limit: 10000,
          enabled: true,
          product_type: product_type,
          provider: this.provider,
        },
      })
      const res = response.data
      if (res.data && res.data.length > 0) {
        this.productOpts = res.data
        console.log('data=', res.data)
      } else {
        this.productOpts = []
      }
    },
    handleProviderChange (val) {
      this.$emit('providerChange', val)
      // const { product_type } = this.form.fc.getFieldsValue()
      console.log('handleProviderChange', val)
      if (val && this.product_type) {
        this.fetchProducts(this.product_type)
      } else {
        this.productOpts = []
      }
    },
    handleProductTypeChange (val) {
      this.$emit('productTypeChange', val)
      // const { provider } = this.form.fc.getFieldsValue()
      console.log('handleProductTypeChange', val)
      if (val && this.provider) {
        this.fetchProducts(val)
      } else {
        this.productOpts = []
      }
    },
    handleNameChange (val) {
      console.log(val)
      this.$emit('nameChange', val)
    },
    handleChargeTypeChange (val) {
      console.log(val)
      this.$emit('chargeTypeChange', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.pricing-search{
  width: 100%;
  padding: 10px 0;
  .search-item{
    width: 100%;
  }
}
</style>
