<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">
      将{{ params.userName }}的二次定价数据复制到以下租户二次定价模块
    </div>
    <div slot="body">
      <div>
        <p>重要提示</p>
        <p>
          复制定价数据将完全覆盖租户已有的二次定价数据，该操作不可逆，请谨慎操作。
        </p>
        <p>
          该操作仅复制定价数据，资源上下架及是否启用二次定价等功能不受影响。
        </p>
      </div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="formRules"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 12 }">
        <a-form-model-item label="目标租户" prop="targetTenants">
          <a-select
            mode="multiple"
            v-model="formData.targetTenants"
            placeholder="请选择目标租户">
            <template v-for="item of customerList">
              <a-select-option :key="item.id" :value="item.id">{{
                item.name
              }}</a-select-option>
            </template>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div slot="footer">
      <div class="">
        <a-button type="primary" @click="confirmCopy" :loading="loading" :disabled="copyDisable">确认复制</a-button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import http from '@/utils/http'

export default {
  name: 'CopyPricingForCustomerDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    const targetValidate = (rule, value, callback) => {
      if (value?.length > 10) {
        return callback(new Error('目标租户不能超过10个'))
      }
      callback()
    }
    return {
      loading: false,
      formData: {
        targetTenants: [],
      },
      formRules: {
        targetTenants: [
          { required: true, message: '请选择目标租户', trigger: 'change' },
          { validator: targetValidate, trigger: 'change' },
        ],
      },
      customerList: [],
    }
  },
  computed: {
    copyDisable () {
      return this.formData.targetTenants.length === 0
    },
  },
  async created () {
    this.getCustomer()
    console.log('this.params=', this.params)
  },
  methods: {
    async getCustomer () {
      const params = {
        scope: 'system',
        show_fail_reason: true,
        details: true,
        user_type: 'customer',
      }
      const result = await http.get('/v1/customers', { params: params })
      if (+result.status !== 200) return
      this.customerList = result.data.data.filter(item => item.id !== this.params.userId)
    },
    async confirmCopy () {
      const valid = await this.$refs.ruleForm.validate()
      if (!valid) return
      this.loading = true
      try {
        const params = {
          from_user_id: this.params.userId,
          to_user_ids: this.formData.targetTenants,
        }
        const result = await this.$http.post('/v3/user_second_prices/copy-second-price', params)
        if (+result.status !== 200) return
        this.cancelDialog()
        this.createDialog('CopyPricingListForCustomerDialog', {
          userId: this.params.userId,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
