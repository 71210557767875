import {
  // getProjectTableColumn,
  // getRegionTableColumn,
  // getStatusTableColumn,
  // getBrandTableColumn,
  // getCopyWithContentTableColumn,
  getNameDescriptionTableColumn,
  // getBillingTypeTableColumn,
  // getOsArch,
  getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import SystemIcon from '@/sections/SystemIcon'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'
// import { PRODUCT_TYPES } from '../../../constant'

export default {
  created () {
    this.columns = [
      getNameDescriptionTableColumn({
        onManager: this.onManager,
        hideField: true,
        field: 'product_name',
        edit: false,
        editDesc: false,
        showDesc: false,
        // addLock: true,
        // addBackup: true,
        // formRules: [
        //   { required: true, message: i18n.t('compute.text_210') },
        //   { validator: this.$validate('resourceCreateName') },
        // ],
        slotCallback: row => {
          return (
            <side-page-trigger onTrigger={ () => this.handleOpenSidepage(row) }>{ row.product_name }</side-page-trigger>
          )
        },
      }),
      {
        field: 'product_type',
        title: '类型',
        slots: {
          default: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PRODUCT_TYPE).find(item => item.value === row.product_type)
            return [<span>{item?.label || '-'}</span>]
          },
        },
      },
      {
        field: 'provider',
        title: '厂商',
        slots: {
          default: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PROVIDER).find(item => item.value === row.provider)
            return [<span>{item?.label || '-'}</span>]
          },
        },
      },
      // getBrandTableColumn({
      //   title: '厂商',
      //   field: 'provider',
      // }),
      {
        field: 'enabled',
        title: '状态',
        slots: {
          default: ({ row }) => {
            return [<span>{ row.enabled ? '上架' : '下架' } </span>]
          },
        },
      },
      {
        field: 'charge_type',
        title: '计费方式',
        slots: {
          default: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.CHARGE_TYPE).find(item => item.value === row.charge_type)
            return [<span>{item?.label || '-'}</span>]
          },
        },
      },
      // {
      //   field: 'coefficient',
      //   title: '定价',
      //   slots: {
      //     default: ({ row }) => {
      //       return [<span>{ '厂商价x' + row.coefficient + '倍' } </span>]
      //     },
      //   },
      // },
      getTimeTableColumn({
        title: '上架时间',
        field: 'up_shelf_at',
      }),
      // getStatusTableColumn({ statusModule: 'servertemplate', vm: this }),
      // getOsArch({ field: 'content.os_arch' }),
      // {
      //   field: 'instance_type',
      //   title: i18n.t('table.title.flavor'),
      //   showOverflow: 'ellipsis',
      //   minWidth: 120,
      //   sortable: true,
      //   slots: {
      //     default: ({ row }) => {
      //       if (!row.config_info) return [<data-loading />]
      //       const { sku, disks } = row.config_info
      //       const diskSize = disks.map(item => item.size_mb).reduce((a, b) => {
      //         return a + b
      //       })
      //       const ret = []
      //       if (row.name) {
      //         ret.push(<div class='text-truncate' style={{ color: '#0A1F44' }}>{ sku.name }</div>)
      //       }
      //       const config = sku.cpu_core_count + 'C' + sizestr(sku.memory_size_mb, 'M', 1024) + (diskSize ? sizestr(diskSize, 'M', 1024) : '')
      //       return ret.concat(<div class='text-truncate' style={{ color: '#53627C' }}>{ config }</div>)
      //     },
      //   },
      //   formatter: ({ row }) => {
      //     const { sku, disks } = row.config_info || {}
      //     const diskSize = disks.map(item => item.size_mb).reduce((a, b) => {
      //       return a + b
      //     })
      //     const ret = []
      //     if (row.name) {
      //       ret.push(sku.name)
      //     }
      //     const config = sku.cpu_core_count + 'C' + sizestr(sku.memory_size_mb, 'M', 1024) + (diskSize ? sizestr(diskSize, 'M', 1024) : '')
      //     return ret.concat(config).join(',')
      //   },
      // },
      // {
      //   field: 'os_type',
      //   title: i18n.t('table.title.os'),
      //   width: 50,
      //   slots: {
      //     default: ({ row }) => {
      //       let name = (row.metadata && row.metadata.os_distribution) ? row.metadata.os_distribution : row.os_type || ''
      //       if (name.includes('Windows') || name.includes('windows')) {
      //         name = 'Windows'
      //       }
      //       const version = (row.metadata && row.metadata.os_version) ? `${row.metadata.os_version}` : ''
      //       const tooltip = (version.includes(name) ? version : `${name} ${version}`) || i18n.t('compute.text_339') // 去重
      //       return [
      //         <SystemIcon tooltip={ tooltip } name={ name } />,
      //       ]
      //     },
      //   },
      //   formatter: ({ row }) => {
      //     let name = (row.metadata && row.metadata.os_distribution) ? row.metadata.os_distribution : row.os_type || ''
      //     if (name.includes('Windows') || name.includes('windows')) {
      //       name = 'Windows'
      //     }
      //     return name
      //   },
      // },
      // {
      //   field: 'config_info.image',
      //   title: i18n.t('res.image'),
      //   showOverflow: 'ellipsis',
      //   minWidth: 190,
      //   slots: {
      //     default: ({ row }) => {
      //       if (!row.config_info) return [<data-loading />]
      //       return row.config_info.image
      //     },
      //   },
      //   formatter: ({ row }) => {
      //     return row.config_info?.image
      //   },
      // },
      // getCopyWithContentTableColumn({ field: 'vpc', title: 'VPC', vm: this }),
      // getBillingTypeTableColumn(),
      // getBrandTableColumn(),
      // getProjectTableColumn(),
      // getRegionTableColumn(),
      // getTimeTableColumn(),
    ]
  },
}
