<template>
  <base-dialog @cancel="cancelDialog" :width="1200">
    <div slot="header" class="header-tittle">区域映射表</div>
    <div slot="body">
      <div class="modal-body">
        <div class="modal-body-box">
          <div class="modal-body-tips">
            <span>1、整体命名为：杭州-101；文字为地域，后方第一位数字为公有云代号，第二、第三位数字为可用区；</span>
            <br />
            <span>2、阿里云可用区数字范围101-199，腾讯云可用区数字范围201-299，移动云可用区数字范围301-399。</span>
          </div>
          <div class="modal-body-top">
            <span class="modal-body-tittle">{{ providerName }}地域名称</span>
            <span class="modal-body-tittle">{{ providerName }}可用区名称</span>
            <span class="modal-body-tittle">芒果云区域名称</span>
          </div>
          <div class="modal-body-content">
            <div class="body-content-left">
              <div
                v-for="city in cityList"
                :key="city.value"
                @click="handleCityClick(city)">
                <div class="city-item-left" :title="city.label">
                  <span class="city-item-title">{{ city.label }}</span>
                </div>
              </div>
            </div>
            <div class="body-content-middle">
              <div
                class="region-item"
                v-for="item in regionList"
                :key="item.zone_id">
                <div class="region-item-left" :title="item.zone_name">
                  <span>{{ item.zone_name }}</span>
                </div>
              </div>
            </div>
            <div class="body-content-right">
              <div
                class="region-item"
                v-for="item in regionList"
                :key="item.zone_id">
                <div class="mapping-item-left">
                  <a-input
                    :class="{ 'highlight': nameCounts[item.mapping_name] > 1 }"
                    v-model="item.mapping_name"
                    :placeholder="item.parantCityLabel"
                    @blur="handleBlur(item)" />
                    <span v-if="nameCounts[item.mapping_name] > 1" class="region-item-tip">命名重复，请修改后提交</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认可用区域</a-button>
      <a-button @click="cancelDialog">{{ $t("dialog.cancel") }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'AreaMappingTable',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      treeData: [],
      cityList: [],
      regionList: [],
      loading: false,
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 6,
        },
      },
    }
  },
  computed: {
    // 统计区域名称重复次数
    nameCounts () {
      const counts = {}
      this.regionList.forEach(obj => {
        if (!obj.mapping_name) return
        counts[obj.mapping_name] = (counts[obj.mapping_name] || 0) + 1
      })
      return counts
    },
    providerName () {
      return this.getDictDataLabel(this.DICT_TYPE.PROVIDER, this.params.row.provider)
    },
  },
  created () {
    this.loadAreaMappingsTree()
  },
  methods: {
    // 去除用户输入空格
    handleBlur (item) {
      item.mapping_name = item.mapping_name.trim()
    },
    // 切换tab
    // 加载区域映射表
    loadAreaMappingsTree () {
      this.$http
        .get(
          `/v2/area_mappings/region-list?provider=${this.params.row.provider}&limit=100`,
        )
        .then((response) => {
          const data = response.data
          if (!data?.length) return
          this.treeData = data
          this.cityList = data.map((item) => ({
            label: item.region_name,
            value: item.region_id,
          }))
        })
    },
    handleCityClick (city) {
      this.regionList = this.treeData.find((item) => item.region_id === city.value)?.zone_list || []
      this.regionList.forEach(item => {
        item.region_name = city.label
        item.region_id = city.value
        item.parantCityLabel = city.label.split('（')[1]?.split('）')[0]
      })
    },
    // 检查输入区域名称是否一样
    checkMappingNameIsSame () {
      return Object.values(this.nameCounts).some(item => item > 1)
    },
    // 将该城市下的所有可用区添加到可用区域列表中
    async handleConfirm () {
      if (this.checkMappingNameIsSame()) return
      const item = this.getDictDatas(this.DICT_TYPE.PROVIDER).find(item => item.value === this.params.row.provider)
      const postDate = {
        provider: this.params.row.provider,
        provider_name: item.label,
        create_list: this.treeData.flatMap(item => item.zone_list),
      }
      this.loading = true
      try {
        const result = await this.$http.post('/v2/area_mappings/update-mapping', postDate)
        if (result.status !== 200) return
        this.$message.success('操作成功')
        this.cancelDialog()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.highlight {
  border-color: red;
}
::v-deep .ant-modal-body {
  padding-top: 0;
  font-size: 16px;
}
.header-tittle {
  font-size: 24px;
  line-height: 36px;
  height: 36px;
  color: #333333;
  user-select: none;
}

.modal-body-box {
  margin-top: 10px;
}

.modal-body-tips {
  margin: 0 0 20px 30px;
  color: #a3a3a3;
}
.modal-body-top {
  display: flex;
  color: #a3a3a3;
  font-size: 24px;
  line-height: 36px;
  height: 36px;
  border-bottom: 1px solid #e8e8e8;
}
.modal-body-tittle {
  flex: 1;
  display: flex;
  justify-content: center;
}
.modal-body-content {
  display: flex;
  margin-top: 10px;
}
.body-content-left,
.body-content-middle,
.body-content-right {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column
}
.city-item-left,
.region-item-left{
  height: 52px;
  cursor: pointer;
}
.region-item{
  width: 240px;
}
.mapping-item-left{
  height: 52px;
}
.region-item-tip{
  display: block;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: red;
}

</style>
