<template>
  <div class="d-flex flex-direction-column align-items-center mt-2">
    <a-card
      :bordered="false"
      class="license-card"
      :bodyStyle="cardNoPadding"
      :headStyle="cardNoPadding"
      size="small">
      <div slot="title">
        <span style="font-size:24px">{{$t('scope.text_145')}}</span>
      </div>
      <div class="mt-2">
        <div class="d-flex mb-3" v-for="(item,index) in versionInfoList" :key="index">
          <div class="license-label flex-grow-0 flex-shrink-0">{{item.label}}:</div>
          <div class="flex-fill">{{item.value}}</div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import setting from '@/config/setting'

export default {
  name: 'Licenses',
  props: {
  },
  data () {
    return {
      email: 'info@yunion.cn',
      copyright: '湖南芒果融创科技有限公司',
      defaultVersionInfoList: [
        {
          label: this.$t('scope.text_146'),
          value: '芒果云',
        },
        {
          label: this.$t('scope.text_147'),
          value: 'v1.4.0',
        },
        {
          label: this.$t('scope.text_156'),
          value: '湖南芒果融创科技有限公司',
        },
      ],
    }
  },
  computed: {
    versionInfoList () {
      const list = this.getDictDatas(this.DICT_TYPE.VERSION_INFO)
      return list || this.defaultVersionInfoList
    },
    version () {
      if (setting.oemVersion) {
        return `v${setting.oemVersion.replace(/^[v]/, '')}`
      }
      return '-'
    },
  },
}
</script>

<style lang="less" scoped>
.license-card{
  width: 60%;
  min-width: 900px;
  max-width: 100%;
}
.flex-direction-column{
  flex-direction: column;
  min-width: 950px;
}
.license-label {
  width: 130px;
}
</style>
